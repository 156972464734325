import React from 'react';

const ImageFormatter = ({ className, webpImage, image, alt, containerClass, width, height, clickable, onClick, to }) => {
  const _clickable = clickable ?? false;
  const handleClick = () => {
    onClick(to)
  }
  return (
    <picture className={containerClass} onClick={_clickable ? handleClick : null}>
      <source srcSet={webpImage} type='image/webp' />
      <img className={className} src={image} alt={alt} width={width} height={height}/>
    </picture>
  );
};
export default ImageFormatter;