import React from 'react';
import {
	ServicePage,
	ContactFormulaire,
	Footer, 
	Header,
	WhatsappWidget,
} from '../components/index'
import useHelmet from '../components/Hooks/useHelmet';
import { helmetPages } from '../config/config';

const longTermRentalSenegal = require('../images/pages/services/long-term-rental-senegal.jpg')
const longTermRentalSenegalWebp = require('../images/pages/services/long-term-rental-senegal.webp')
const longTermRentalDakar = require('../images/pages/services/long-term-rental-dakar.jpg')
const longTermRentalDakarWebp = require('../images/pages/services/long-term-rental-dakar.webp')

const ServiceLongueDuree = () => {
	const helmet = useHelmet(helmetPages?.LONG);
	return (
		<main className="Main">
			{helmet}
			<WhatsappWidget />
			<Header	/>
			<div className="MainSectionsContainer">
				<ServicePage 
					pageText="longServiceText"
					image1={longTermRentalSenegal}
					image1Webp={longTermRentalSenegalWebp}
					image2={longTermRentalDakar}
					image2Wepb={longTermRentalDakarWebp}
					vh={true}
					hotelList={true}
				/>
			</div>
			<Footer />
			<ContactFormulaire />
		</main>
	)
}
export default ServiceLongueDuree;