import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { webConfig, defaultWebConfig } from "../../config/config";
import { goTo, goToTop } from "../../utils";

export const DisplayContext = createContext();

export const DisplayContextProvider = ({ children }) => {
  const [reviews, setReviews] = useState(defaultWebConfig?.reviews);
  const [destinationsList, setDestinationsList] = useState(defaultWebConfig?.destinationsList);
  const [hotelList, setHotelList] = useState(defaultWebConfig?.hotelList);
  const [prices, setPrices] = useState(defaultWebConfig?.prices);

  useEffect(() => {
    const getWebConfig = async () => {
      const _webConfig = await fetch(webConfig?.WEB_CONFIG_URL)
      const webConfigJson = await _webConfig.json()
      setDestinationsList(webConfigJson?.destinationsList)
      setHotelList(webConfigJson?.hotelList)
      setPrices(webConfigJson?.prices)
      setReviews(webConfigJson?.reviews)
    }
    getWebConfig();
  },[])

  const [isBookingFormOpen, setIsBookingFormOpen] = useState(false);
	const [isContactFormOpen, setIsContactFormOpen] = useState(false);
	const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);
	const [isPricingPopUpOpen, setIsPricingPopUpOpen] = useState(false);
  const [isVhSectionExpanded, setIsVhSectionExpanded] = useState(false);
  const [isVh1Expanded, setIsVh1Expanded] = useState(false);
  const [isVh2Expanded, setIsVh2Expanded] = useState(false);
	
  const [displayBookingFormConfirmation, setDisplayBookingFormConfirmation] = useState(false);
	const [isSendingBookingRequest, setIsSendingBookingRequest] = useState(false);
  const [isBookingRequestSuccessful, setIsBookingRequestSuccessful] = useState(false);

  const [displayContactConfirmation, setDisplayContactConfirmation] = useState(false);
  const [isSendingContactRequest, setIsSendingContactRequest] = useState(false);
  const [isContactRequestSuccessful, setIsContactRequestSuccessful] = useState(false);

  const handleBookingFormFocus = useCallback(() => {
    if (!isBookingFormOpen) {
			goTo("FormBorder")
			setIsBookingFormOpen(true)
    } else {
			goToTop()
			setIsBookingFormOpen(false)
		}
  }, [isBookingFormOpen])

	const handleBookingFormFocusAlways = useCallback(() => {
    setIsBurgerMenuOpen(false)
    setIsContactFormOpen(false)
    setIsPricingPopUpOpen(false)
		goTo("FormBorder")
		setIsBookingFormOpen(true)
	}, [])

  const handleFocusOnForm = useCallback(() => {
    if (!isBookingFormOpen) {
			goTo("FormBorder")
			setIsBookingFormOpen(!isBookingFormOpen)
    }
  }, [isBookingFormOpen])

  const handleLoseFocus = useCallback(() => {
    if (isBookingFormOpen) {
			setIsBookingFormOpen(!isBookingFormOpen)
    }
		if (isPricingPopUpOpen) {
			setIsPricingPopUpOpen(false)
		}
  }, [isBookingFormOpen, isPricingPopUpOpen])

  const handleReduceForm = useCallback(() => {
    if (isBookingFormOpen) {
			setIsBookingFormOpen(!isBookingFormOpen)
    }
		if (isPricingPopUpOpen) {
			setIsPricingPopUpOpen(false)
		}
		goToTop()
  }, [isBookingFormOpen, isPricingPopUpOpen])

  const openBookingConfirmationPopUp = useCallback(() => {
		setDisplayBookingFormConfirmation(true)
  }, [])

  const closeBookingConfirmationPopUp = useCallback((withError) => {
		setDisplayBookingFormConfirmation(false)
    if (!withError) {
      handleLoseFocus()
    }
  }, [handleLoseFocus])

	// Contact Form
	const toggleContactForm = useCallback(() => {
    setIsPricingPopUpOpen(false)
		setIsContactFormOpen(!isContactFormOpen)
	}, [isContactFormOpen])

  const closeContactForm = useCallback(() => {
		setIsContactFormOpen(false)
  }, [])

  const openContactConfirmationPopUp = useCallback(() => {
    setDisplayContactConfirmation(true)
    closeContactForm()
  }, [closeContactForm])

	// Burger menu
	const toggleBurgerMenu = useCallback(() => {
		setIsPricingPopUpOpen(false)
		setIsContactFormOpen(false)
		setIsBurgerMenuOpen(!isBurgerMenuOpen)
	}, [isBurgerMenuOpen])

	// Pricing pop-up
	const openPricingPopUp = useCallback(() => {
		setIsContactFormOpen(false)
		setIsPricingPopUpOpen(true)
	}, [])

	const closePricingPopUp = useCallback(() => {
		setIsPricingPopUpOpen(false)
	}, [])

	const closePricingPopUpAndOpenContactForm = useCallback(() => {
		setIsPricingPopUpOpen(false)
		toggleContactForm()
	}, [toggleContactForm])

  // Expandable components
  const toggleVhSection = useCallback(() => {
    setIsVhSectionExpanded(!isVhSectionExpanded)
  }, [isVhSectionExpanded])
  
  const toggleVh1 = useCallback(() => {
    setIsVh1Expanded(!isVh1Expanded)
  }, [isVh1Expanded])
  
  const toggleVh2 = useCallback(() => {
    setIsVh2Expanded(!isVh2Expanded)
  }, [isVh2Expanded])

  const contextValue = useMemo(() => ({
    // googleTotalReviews,
    // googleRating,
    // taTotalReviews,
    // taRating,
    isBookingFormOpen,
    isContactFormOpen,
    isBurgerMenuOpen,
    isPricingPopUpOpen,
    isVhSectionExpanded,
    isVh1Expanded,
    isVh2Expanded,
    displayBookingFormConfirmation,
    isSendingBookingRequest,
    isBookingRequestSuccessful,
    displayContactConfirmation,
    isSendingContactRequest,
    isContactRequestSuccessful,
    reviews,
    destinationsList,
    hotelList,
    prices,
    setIsSendingBookingRequest,
    setIsBookingRequestSuccessful,
    setIsSendingContactRequest,
    setIsContactRequestSuccessful,
    setDisplayContactConfirmation,
    handleBookingFormFocus,
    handleBookingFormFocusAlways,
    handleFocusOnForm,
    handleLoseFocus,
    handleReduceForm,
    openBookingConfirmationPopUp,
    closeBookingConfirmationPopUp,
    toggleContactForm,
    closeContactForm,
    openContactConfirmationPopUp,
    toggleBurgerMenu,
    openPricingPopUp,
    closePricingPopUp,
    closePricingPopUpAndOpenContactForm,
    goTo,
    goToTop,
    toggleVhSection,
    toggleVh1,
    toggleVh2
  }), [
    // googleTotalReviews,
    // googleRating,
    // taTotalReviews,
    // taRating,
    isBookingFormOpen,
    isContactFormOpen,
    isBurgerMenuOpen,
    isPricingPopUpOpen,
    isVhSectionExpanded,
    isVh1Expanded,
    isVh2Expanded,
    displayBookingFormConfirmation,
    isSendingBookingRequest,
    isBookingRequestSuccessful,
    displayContactConfirmation,
    isSendingContactRequest,
    isContactRequestSuccessful,
    reviews,
    destinationsList,
    hotelList,
    prices,
    setIsSendingBookingRequest,
    setIsBookingRequestSuccessful,
    setIsSendingContactRequest,
    setIsContactRequestSuccessful,
    setDisplayContactConfirmation,
    handleBookingFormFocus,
    handleBookingFormFocusAlways,
    handleFocusOnForm,
    handleLoseFocus,
    handleReduceForm,
    openBookingConfirmationPopUp,
    closeBookingConfirmationPopUp,
    toggleContactForm,
    closeContactForm,
    openContactConfirmationPopUp,
    toggleBurgerMenu,
    openPricingPopUp,
    closePricingPopUp,
    closePricingPopUpAndOpenContactForm,
    toggleVhSection,
    toggleVh1,
    toggleVh2
  ]);

  return (
    <DisplayContext.Provider value={contextValue}>
      {children}
    </DisplayContext.Provider>
  );
}