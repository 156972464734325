import React from 'react';

const ImageProvider = ({ 
  className, 
  webpImageDesktop,
  webpImageDesktop2x,
  webpImageTablet, 
  webpImageTablet2x,
  webpImageMobile,
  webpImageMobile2x,
  imageDefault, 
  alt, 
  containerClass,
  width,
  height 
}) => {
  return (
    <picture className={containerClass}>
      <source
        srcSet={`
          ${webpImageDesktop} 1920w, ${webpImageDesktop2x} 3840w,
          ${webpImageTablet} 1024w, ${webpImageTablet2x} 2048w,
          ${webpImageMobile} 375w, ${webpImageMobile2x} 750w
        `}
        type="image/webp"
        media="(min-width: 1200px)"
      />
      <source
        srcSet={`
          ${webpImageTablet} 1024w, ${webpImageTablet2x} 2048w,
          ${webpImageMobile} 375w, ${webpImageMobile2x} 750w
        `}
        type="image/webp"
        media="(min-width: 768px)"
      />
      <source
        srcSet={`
          ${webpImageMobile} 375w, ${webpImageMobile2x} 750w
        `}
        type="image/webp"
      />
      <img width={width} height={height} className={className} src={imageDefault} alt={alt} />
    </picture>
  );
};

export default ImageProvider;