import React, { useContext } from 'react';
import {
  DisplayContext,
  useTranslation,
  LinksDisplayer
} from '../../index';
import Accordion from '../../Reusables/Accordion';
import { externalLinks } from '../../../config/externalLinks';

const Faq = () => {
  const { handleLoseFocus } = useContext(DisplayContext);
  const faqSection = useTranslation("faqSection")
  const faqQuestions1 = faqSection?.questions.slice(0, 3);
  const faqQuestions2 = faqSection?.questions.slice(3, 6);

  return (
    <div className="Section" onClick={handleLoseFocus}>
      <div className="SectionContained">
        <h2 className="SectionTitle" id="goToFaq">
          {faqSection?.title}
        </h2>
        <div className="AccordionContainer">
          <Accordion text={faqQuestions1} />
          <Accordion text={faqQuestions2} />
        </div>
        <div>
          <h3 className="SectionSubTitle">{faqSection?.externalLinks}</h3>
          <LinksDisplayer links={externalLinks} />
        </div>
      </div>
    </div>
  )
}
export default Faq;