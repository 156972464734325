import React from 'react';
import styled from 'styled-components';
import { themeColors } from '../../utils';

export const StyledBurger = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 22px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 5;
  
  &:focus {
    outline: none;
  }
  
  div {
    width: 22px;
    height: 2px;
    background: ${themeColors.yobaleLogoGreen};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

  }
  div:first-of-type {
    background: ${themeColors.yobaleLogoGreen};
    transform: ${props => props.isBurgerMenuOpen ? 'rotate(45deg)' : 'rotate(0)'};
  }

  div:nth-of-type(2) {
    background: ${themeColors.yobaleLogoGreen};
    opacity: ${props => props.isBurgerMenuOpen ? '0' : '1'};
    transform: ${props => props.isBurgerMenuOpen ? 'translateX(-20px)' : 'translateX(0)'};
  }

  div:last-of-type {
    background: ${themeColors.yobaleLogoGreen};
    transform: ${props => props.isBurgerMenuOpen ? 'rotate(-45deg)' : 'rotate(0)'};
  }
`;

const Burger = (props) => {
  return (
    <div className="BurgerContainer">
      <StyledBurger isBurgerMenuOpen={props.isBurgerMenuOpen} onClick={props.toggleBurgerMenu} >
        <div />
        <div />
        <div />
      </StyledBurger>
    </div>
  )
}

export default Burger