import { useContext } from 'react';
import {
  DisplayContext,
  PopularDestinations,
  PopularRoutes,
  RouteCTA,
  RoutePerks,
  useTranslation,
} from '../../index';

const Routes = () => {
  const { handleLoseFocus } = useContext(DisplayContext);
  const routesSectionText = useTranslation("routesSectionText");
  
  return (
    <div className="Section" onClick={handleLoseFocus}>
      <div className="SectionContained">
        <h2 className="SectionTitle" id="goToRoutes">
          {routesSectionText.title}
        </h2>
        <div className="RouteSubSection">
          <PopularDestinations />
        </div>
        <div className="RouteSubSection">
          <PopularRoutes />
        </div>
        <div className="RouteSubSection">
          <RoutePerks />
        </div>
        <div>
          <RouteCTA />
        </div>
      </div>
    </div>
  )
}
export default Routes;