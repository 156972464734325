import { useState, useEffect } from "react";
import useTranslation from "../Hooks/useTranslation";

const ExpandContainer = ({children, stateStatus, toggleCallback, displayButton, buttonOnly, containerHeight}) => {
  const buttonsText = useTranslation("buttonsText");
  const [text, setText] = useState(buttonsText?.knowMore);
  const _containerHeight = stateStatus ? containerHeight ?? "100%" : "0px";

  const handleClick = () => {
    toggleCallback && toggleCallback();
  }

  useEffect(() => {
    if(stateStatus) {
      setTimeout(() => {
        setText(buttonsText?.knowLess);
      }, 150);
    } else {
      setTimeout(() => {
        setText(buttonsText?.knowMore);
      }, 150);
    }
  }, [buttonsText?.knowMore, buttonsText?.knowLess, stateStatus]);

  return (
    <>
      {buttonOnly ? null :
        <div style={{height:_containerHeight, overflow:"hidden", transition:"all 0.4s ease 0.1s", marginBottom:"10px"}}>
          {children}
        </div>
      }
      {displayButton && <button className="ExpandButton" onClick={() => handleClick()}>{text}</button>}
    </>
  )
}
export default ExpandContainer;