import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  ExpandContainer,
  DisplayContext,
  // ImageFormatter,
  useDevice,
  useTranslation,
} from '../../index';
import CarLeft from '../../Reusables/Images/CarLeft';
import CarRight from '../../Reusables/Images/CarRight';

// const coffreImage = require('../../../images/vh/yobale-transport-bagages-aeroport-dakar.jpg')
// const coffreImageWebp = require('../../../images/vh/yobale-transport-bagages-aeroport-dakar.webp')
// const gpsCar = require('../../../images/vh/voitures-gps-geolocalisées.png')
// const gpsCarWebp = require('../../../images/vh/voitures-gps-geolocalisées.webp')

const StyledVehicleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  gap: 10%;
  transition: all 0.4s ease 0.1s;
  height: ${(props) => (props.isVhSectionExpanded ? '600px' : '350px')};

  @media (max-width: 1280px) {
    height: ${(props) => (props.isVhSectionExpanded ? '600px' : '300px')};
  }

  @media (max-width: 769px) {
    height: ${(props) => (props.isVhSectionExpanded ? '640px' : '270px')};
  }

  @media (max-width: 700px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 40px;
    height: auto;
  }
`;

const Vehicules = (props) => {
  const { mobile } = useDevice();
  const {
    handleLoseFocus,
    isVhSectionExpanded,
    isVh1Expanded,
    isVh2Expanded,
    toggleVhSection,
    toggleVh1,
    toggleVh2,
  } = useContext(DisplayContext);
  const vehiculesSection = useTranslation('vehiculesSection');

  return (
    <div className="Section" onClick={handleLoseFocus}>
      <div className="SectionContained">
        <h2 className="SectionTitle" id="goToVehicules">
          {vehiculesSection.title}
        </h2>

        {!mobile ? (
          <>
            <StyledVehicleContainer isVhSectionExpanded={isVhSectionExpanded}>
              <div className="VehiculesContainerLeft">
                <div className="VehiculeImageContainerLeft">
                  <CarLeft
                    className="VehiculesImage1"
                    alt="yobale-navette-aeroport-dakar"
                    containerClass="VehiculeContainerClass"
                  />
                </div>
                <div className="VehiculeTextContainerLeft">
                  <h2
                    className="SectionSubTitle"
                    style={{ textAlign: 'center' }}
                  >
                    {vehiculesSection.car1Title}
                  </h2>
                  <ExpandContainer
                    stateStatus={isVhSectionExpanded}
                    toggleCallback={toggleVhSection}
                  >
                    <p className="ParagraphLeft">
                      <strong>{vehiculesSection.carOption1}</strong>
                      {vehiculesSection.car1Cat}
                    </p>
                    <p className="ParagraphLeft">
                      <strong>{vehiculesSection.carOption2}</strong>
                      {vehiculesSection.car1Cap}
                    </p>
                    <p className="ParagraphLeft">
                      <strong>{vehiculesSection.carOption3}</strong>
                    </p>
                    <p className="ParagraphLeft">
                      &#10132;{vehiculesSection.car1Bag1}
                    </p>
                    <p className="ParagraphLeft">
                      &#10132;{vehiculesSection.car1Bag2}
                    </p>
                    <p className="ParagraphLeft">
                      <strong>{vehiculesSection.car1Coffre1}</strong>
                      {vehiculesSection.car1Coffre2}
                    </p>
                  </ExpandContainer>
                </div>
              </div>
              <div className="VehiculesContainerRight">
                <div className="VehiculeImageContainerRight">
                  <CarRight
                    className="VehiculesImage2"
                    alt="yobale-transfert-aeroport-dakar"
                    containerClass="VehiculeContainerClass"
                  />
                </div>
                <div className="VehiculeTextContainerRight">
                  <h2
                    className="SectionSubTitle"
                    style={{ textAlign: 'center' }}
                  >
                    {vehiculesSection.car2Title}
                  </h2>
                  <ExpandContainer
                    stateStatus={isVhSectionExpanded}
                    toggleCallback={toggleVhSection}
                  >
                    <p className="ParagraphLeft">
                      <strong>{vehiculesSection.carOption1}</strong>
                      {vehiculesSection.car2Cat}
                    </p>
                    <p className="ParagraphLeft">
                      <strong>{vehiculesSection.carOption2}</strong>
                      {vehiculesSection.car2Cap}
                    </p>
                    <p className="ParagraphLeft">
                      <strong>{vehiculesSection.carOption3}</strong>
                      {vehiculesSection.car2Bag1}
                    </p>
                    <p className="ParagraphLeft">
                      <strong>{vehiculesSection.car2Coffre1}</strong>
                      {vehiculesSection.car2Coffre2}
                    </p>
                  </ExpandContainer>
                </div>
              </div>
            </StyledVehicleContainer>
            <ExpandContainer
              stateStatus={isVhSectionExpanded}
              toggleCallback={toggleVhSection}
              displayButton
              buttonOnly
            ></ExpandContainer>
          </>
        ) : (
          <>
            <StyledVehicleContainer isVhSectionExpanded={isVhSectionExpanded}>
              <div className="VehiculesContainerLeft">
                <div className="VehiculeImageContainerLeft">
                  <CarLeft
                    className="VehiculesImage1"
                    alt="yobale-navette-aeroport-dakar"
                    containerClass="VehiculeContainerClass"
                  />
                </div>
                <div className="VehiculeTextContainerLeft">
                  <h2
                    className="SectionSubTitle"
                    style={{ textAlign: 'center' }}
                  >
                    {vehiculesSection.car1Title}
                  </h2>
                  <ExpandContainer
                    stateStatus={isVh1Expanded}
                    toggleCallback={toggleVh1}
                    displayButton
                    containerHeight={'335px'}
                  >
                    <p className="ParagraphLeft">
                      <strong>{vehiculesSection.carOption1}</strong>
                      {vehiculesSection.car1Cat}
                    </p>
                    <p className="ParagraphLeft">
                      <strong>{vehiculesSection.carOption2}</strong>
                      {vehiculesSection.car1Cap}
                    </p>
                    <p className="ParagraphLeft">
                      <strong>{vehiculesSection.carOption3}</strong>
                    </p>
                    <p className="ParagraphLeft">
                      &#10132;{vehiculesSection.car1Bag1}
                    </p>
                    <p className="ParagraphLeft">
                      &#10132;{vehiculesSection.car1Bag2}
                    </p>
                    <p className="ParagraphLeft">
                      <strong>{vehiculesSection.car1Coffre1}</strong>
                      {vehiculesSection.car1Coffre2}
                    </p>
                  </ExpandContainer>
                </div>
              </div>
              <div className="VehiculesContainerRight">
                <div className="VehiculeImageContainerRight">
                  <CarRight
                    className="VehiculesImage2"
                    alt="yobale-transfert-aeroport-dakar"
                    containerClass="VehiculeContainerClass"
                  />
                </div>
                <div className="VehiculeTextContainerRight">
                  <h2
                    className="SectionSubTitle"
                    style={{ textAlign: 'center' }}
                  >
                    {vehiculesSection.car2Title}
                  </h2>
                  <ExpandContainer
                    stateStatus={isVh2Expanded}
                    toggleCallback={toggleVh2}
                    displayButton
                    containerHeight={'290px'}
                  >
                    <p className="ParagraphLeft">
                      <strong>{vehiculesSection.carOption1}</strong>
                      {vehiculesSection.car2Cat}
                    </p>
                    <p className="ParagraphLeft">
                      <strong>{vehiculesSection.carOption2}</strong>
                      {vehiculesSection.car2Cap}
                    </p>
                    <p className="ParagraphLeft">
                      <strong>{vehiculesSection.carOption3}</strong>
                      {vehiculesSection.car2Bag1}
                    </p>
                    <p className="ParagraphLeft">
                      <strong>{vehiculesSection.car2Coffre1}</strong>
                      {vehiculesSection.car2Coffre2}
                    </p>
                  </ExpandContainer>
                </div>
              </div>
            </StyledVehicleContainer>
          </>
        )}

        {/* KEEPING SECTION FOR THE PAGE DEVELOPMENT IN THE FUTURE 
        
        <div className="VehiculeOptionsContainer" onClick={props.handleLoseFocus}>
          <div className="VehiculeOptionsContent">
            <ImageFormatter 
              className={"coffreImage"} 
              webpImage={gpsCarWebp} 
              image={gpsCar} 
              alt="coffre-bagages-navette-aéroport-dakar-DSS"
              width={"1440"}
              height={"996"} 
            />
            <p className="VehiculesOptionText">{vehiculesSection.information}</p>
          </div>
          <div className="VehiculeOptionsContent">
            <ImageFormatter 
              className={"coffreImage"} 
              webpImage={coffreImageWebp} 
              image={coffreImage} 
              alt="coffre-bagages-navette-aéroport-dakar-DSS"
              width={"1440"}
              height={"996"} 
            />
            <p className="VehiculesOptionText">{vehiculesSection.information2}</p>
          </div>
        </div> */}
      </div>
    </div>
  );
};
export default Vehicules;
