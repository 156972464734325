import React from 'react';
import Slider from 'react-slick';
import Rating from 'react-rating';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ImageFormatter, useTranslation } from '../../index';
import { truncate } from '../../../utils';
import { reviewsConfig, carouselConfig } from '../../../config/config';

const taLogo = require('../../../images/brand/ta_200.png');
const taLogoWebp = require('../../../images/brand/ta_200.webp');

const ReviewsCarousel = () => {
  const reviewsText = useTranslation('reviewsText');
  const reviews = reviewsText?.reviews;
  const settings = carouselConfig;

  return (
    <Slider {...settings}>
      {reviews.map((review, index) => {
        const date = new Date(review.date);
        const formattedDate = date.toLocaleDateString(navigator.language, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        });
        const truncatedText = truncate(
          review.comment,
          reviewsConfig?.textLength
        );

        return (
          <div key={index} className="review-card">
            <div className="reviewHeader" tabIndex="-1">
              <div className="reviewsDateContainer" tabIndex="-1">
                <Rating
                  className="TripAdvisorRating"
                  placeholderRating={5}
                  emptySymbol={<div className="grey-circle"></div>}
                  placeholderSymbol={<div className="green-circle"></div>}
                  fullSymbol={<div className="green-circle"></div>}
                  readonly
                  tabIndex="-1"
                />
                <p className="review-date" tabIndex="-1">
                  {formattedDate}
                </p>
              </div>
              <a
                className="FooterDescriptionWithLogo footerBrandContainer reviewLogo"
                href={review.url}
                target="_blank"
                rel="noopener noreferrer"
                tabIndex="-1"
              >
                <ImageFormatter
                  className={'taReviewLogo'}
                  webpImage={taLogoWebp}
                  image={taLogo}
                  alt={'trip-advisor-logo'}
                  width={'200'}
                  height={'93'}
                  tabIndex="-1"
                />
              </a>
            </div>
            <h3 className="reviewTitle" tabIndex="-1">
              {review.title}
            </h3>
            <p className="reviewCommment" tabIndex="-1">
              "{truncatedText}
            </p>
            <p className="reviewAuthor" tabIndex="-1">
              {review.author}"
            </p>
            <a
              className="reviewLink"
              href={review.url}
              target="_blank"
              rel="noopener noreferrer"
              tabIndex="-1"
            >
              {reviewsText?.readMore}
            </a>
          </div>
        );
      })}
    </Slider>
  );
};
export default ReviewsCarousel;
