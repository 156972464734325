import { 
  LinksDisplayer, 
  useTranslation 
} from "../../index";
import { partnersLinks } from "../../../config/partners";

const Partners = () => {
  const partnersSectionText = useTranslation("partnersSection");

  return (
    <div>
      <h3 className="SectionSubTitle">{partnersSectionText?.title}</h3>
      <p className="Paragraph">{partnersSectionText?.description1}</p>
      <LinksDisplayer links={partnersLinks} />
    </div>
  )
};
export default Partners;