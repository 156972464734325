import React, { useContext } from 'react';
import {
  DisplayContext, 
  ImageFormatter,
  Partners,
  useTranslation
} from '../../index';

const accueilAeroport = require('../../../images/team/yobale-accueil-aeroport-dakar.jpg')
const accueilAeroportWebp = require('../../../images/team/yobale-accueil-aeroport-dakar.webp')
const vtcHybrid = require('../../../images/team/vtc-hybrid-senegal-2.png')
const vtcHybridWebp = require('../../../images/team/vtc-hybrid-senegal-2.webp')
const chauffeurYobale = require('../../../images/team/chauffeur-yobale-senegal.png')
const chauffeurYobaleWebp = require('../../../images/team/chauffeur-yobale-senegal.webp')

const Team = () => {
  const {handleLoseFocus} = useContext(DisplayContext);
  const teamSectionText = useTranslation("teamSection");

  return (
    <div className="Section" onClick={handleLoseFocus}>
      <div className="SectionContained">

        <h2 className="SectionTitle" id="goToTeam">
          {teamSectionText.title}
        </h2>
        
        <div className="TeamContainer TeamContainerFirst">
          <div className="TeamContainerRight">
            <h3 className="SectionSubTitle my-0">
              {teamSectionText.subtitle1}
            </h3>
            <p className="Paragraph">
              {teamSectionText.description1}
            </p>
            {/* <p className="Paragraph" style={{fontStyle:"italic"}}>
              {teamSectionText.description2} 
            </p>
            <p className="Paragraph">
              {teamSectionText.description2bis} 
            </p> */}
          </div>
          <div className="TeamContainerLeft">
            <ImageFormatter 
              className="TeamImage1" 
              webpImage={accueilAeroportWebp} 
              image={accueilAeroport} 
              alt="accueil-aéroport-dakar" 
              width={400}
              height={450}
            />
          </div>
        </div>

        <div className="TeamContainer">
          <div className="TeamContainerRight">
            <ImageFormatter 
              className="TeamImage" 
              webpImage={vtcHybridWebp} 
              image={vtcHybrid} 
              alt="vtc-hybrid-senegal" 
              width={650}
              height={434}
            />
          </div>
          <div className="TeamContainerLeft">
            <h3 className="SectionSubTitle my-0">
              {teamSectionText.subtitle2}
            </h3>
            <p className="Paragraph">
              {teamSectionText.description3}
            </p>
          </div>
        </div>

        <div className="TeamContainer TeamContainerFirst">
          <div className="TeamContainerLeft">
            <h3 className="SectionSubTitle my-0">
              {teamSectionText.subtitle3}
            </h3>
            <p className="Paragraph">
              {teamSectionText.description4}
            </p>
            <p className="Paragraph ConceptParagraph">
              {teamSectionText.description5}
            </p>
          </div>
          <div className="TeamContainerRight">
            <ImageFormatter 
              className="TeamImage" 
              webpImage={chauffeurYobaleWebp} 
              image={chauffeurYobale} 
              alt="chauffeur-local-senegal" 
              width={650}
              height={434}
            />
          </div>
        </div>
        
        <Partners />
      </div>
    </div>
  )
}
export default Team;