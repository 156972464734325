import React from 'react';

const GreenArrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" width="16" height="16" fill="#006400">
    <g>
      <g>
        <g id="_x32_">
          <g>
            <path d="M962.2,403.4L612.8,20.3c-13.9-13.7-36.3-13.7-50.2,0c-8,7.9-10.8,18.6-9.6,28.9v206.3c-290.1,0-525.2,235.1-525.2,525.2c0,74.4,15.7,145.1,43.6,209.2c41.4-218.8,250.2-385,481.1-385c0,20.7,0,209.9,0,209.9c-1.2,10.3,2.3,17,10.2,24.9c15.3,15.3,39.1,9,52.1-2.1l347.5-381c7.4-7.3,10.5-17.1,10-26.7C972.7,420.4,969.5,410.7,962.2,403.4z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default GreenArrow;