import { useContext } from "react"
import { DisplayContext, LangContext } from "../index"

const usePricing = () => {
  const { lang } = useContext(LangContext)
  const { prices } = useContext(DisplayContext)
  const currentPricing = prices?.map((item) => {
    return (
      {
        ...item,
        from: item[lang].from,
        to: item[lang].to,
      }
    )
  })
  return currentPricing;
}
export default usePricing;