import { useContext } from "react";
import { LangContext } from "../index";
import { langConfig } from "../../config/config";

let translations = {};
langConfig.AVAILABLE_LANGS.forEach(lang => {
  translations[lang] = require(`../../translations/${lang}.json`);
});

const useTranslation = (text) => {
  const { lang } = useContext(LangContext);
  return translations[lang][text];
}
export default useTranslation;