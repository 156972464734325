import React, { useContext } from 'react';
import {
  DisplayContext, 
  useTranslation, 
  ReviewsCarousel
} from '../../index';

const Reviews = () => {
  const { handleLoseFocus } = useContext(DisplayContext);
  const reviewsText = useTranslation("reviewsText");

  return (
    <div className="Section" onClick={handleLoseFocus}>
      <div className="SectionNotContained reviewSection">
        <h2 className="SectionSubTitle SectionContained" id="goToConcept">
          {reviewsText?.reviewTitle}
        </h2>
        <ReviewsCarousel />
      </div>
    </div>
  )
}
export default Reviews;