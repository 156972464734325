import { 
  useTranslation,
  ContactButton 
} from "../../index";

const RouteCTA = () => {
  const routesSectionText = useTranslation("routesSectionText");

  return (
    <>
      <div className="RouteCTA">
        <div className="RouteCTATextContainer">
          <p className="RouteCTAText1">
            {routesSectionText?.ctaText1}
          </p>
          <p className="RouteCTAText2">
            {routesSectionText?.ctaText2}
          </p>
        </div>
        <ContactButton />
      </div>
    </>
  )
}
export default RouteCTA;