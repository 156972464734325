import { 
  useTranslation, 
  usePricing, 
  RouteCard 
} from "../../index";

const PopularRoutes = () => {
  const pricing = usePricing();
  const routesSectionText = useTranslation('routesSectionText');

  return (
    <>
      <h3 className="SectionSubTitle3">
        {routesSectionText?.routes}
      </h3>
      <div className="popularRoutesContainer">
        {pricing.map((item, index) => {
          return (
            <RouteCard props={item} text={routesSectionText?.from} key={index} />
          )}
        )}
      </div>
      <div>
        <div className="RouteDetails">
            {'*Saly, Mbour, Somone, Ngaparou | ** Joal Fadiouth, Fimela, Ndangane, Palmarin.'}
        </div>
      </div>
    </>
  )
}
export default PopularRoutes;