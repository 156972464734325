import React, {
  useContext, 
  useState, 
  useEffect, 
  useRef
} from 'react';
import emailjs from '@emailjs/browser';
import Datetime from "react-datetime";
import { emailConfig } from '../../../../config/config';
import { 
  DisplayContext, 
  GreenArrow,
  LangContext,
  TripAdvisorRating,
  GoogleMapsRating,
  useTranslation 
} from '../../../index';

const initialState = {
  typeVehicule: '',
  typeVehiculeError: '',
  date: '',
  displayedDate: '',
  dateError: '',
  heure: '',
  // heureError: '',
  adresseDepart: '',
  adresseDepartError: '',
  adresseArrivee: '',
  adresseArriveeError: '',
  idVol: '',
  nombrePassagers: '',
  nombrePassagersError: '',
  nombreEnfants: '',
  poidsEnfants: '',
  nombreBagages23: '',
  nombreBagages10: '',
  nombreBagagesError: '',
  nomPassager: '',
  nomPassagerError: '',
  emailClient: '',
  emailClientError: '',
  carteBancaire: '',
  telephone: '',
  telephoneError: '',
  hotel: '',
  commentaires: '',
  // resData: '', // For debugging
}

const BookingForm = () => {
  const {
    isBookingFormOpen, 
    setIsSendingBookingRequest,
    setIsBookingRequestSuccessful,
    handleFocusOnForm,
    handleReduceForm,
    openBookingConfirmationPopUp,
    openPricingPopUp
  } = useContext(DisplayContext);
  const {moment, lang} = useContext(LangContext) 
  const bookingForm = useTranslation("bookingForm")
  
  const [{
    typeVehicule,
    typeVehiculeError,
    date,
    displayedDate,
    dateError,
    heure,
    // heureError,
    adresseDepart,
    adresseDepartError,
    adresseArrivee,
    adresseArriveeError,
    idVol,
    nombrePassagers,
    nombrePassagersError,
    nombreEnfants,
    poidsEnfants,
    nombreBagages23,
    nombreBagages10,
    nombreBagagesError,
    nomPassager,
    nomPassagerError,
    emailClient,
    emailClientError,
    carteBancaire,
    telephone,
    telephoneError,
    hotel,
    commentaires,
    // resData, // For debugging
  }, setState] = useState(initialState);

  // CALENDAR PREPARATION
  // all dates before today disabled inside the calendar
  let yesterday = moment().subtract( 1, 'day' );
  let validDates = function( current ){
      return current.isAfter( yesterday );
  };

  // INSTANTIATE AUTOCOMPLETE BY GOOGLE PLACES API
  const autocompleteDepartRef = useRef(null);
  const autocompleteDestinationRef = useRef(null);
  const inputDepart = document.getElementById('departTextField');
  const inputDestination = document.getElementById('destinationTextField');
  
  useEffect(() => {
    const options = {
      componentRestrictions: {country: 'sn'}
    };
    
    if (inputDepart && inputDestination) {
      //DO NOT REMOVE BELOW LINE !!!//
      /*global google*/
      autocompleteDepartRef.current = new google.maps.places.Autocomplete(inputDepart, options);
      autocompleteDestinationRef.current = new google.maps.places.Autocomplete(inputDestination, options);
      // Avoid paying for data that you don't need by restricting the set of
      // place fields that are returned to just the name
      autocompleteDepartRef.current.setFields(['name']);
      autocompleteDestinationRef.current.setFields(['name']);
      // Fire Event when a suggested name is selected
      autocompleteDepartRef.current.addListener('place_changed', handleDepartSelect)
      autocompleteDestinationRef.current.addListener('place_changed', handleDestinationSelect)
    }
  },[inputDepart, inputDestination])

  const resetFields = () => {
    setState(({...initialState}))
  }

  const handleChange = (event) => {
    const {name, value} = event.target
    setState(prevState => ({...prevState, [name]: value}))
  }

  const handleDepartSelect = () => {
    const addressDepartObject = autocompleteDepartRef.current.getPlace();
    const addressDepartName = addressDepartObject.name;

    // Check if address is valid
    if (addressDepartName) {
      setState(prevState => ({...prevState, adresseDepart: addressDepartName}))
    }
  }

  const handleDestinationSelect = () => {
    const addressDestinationObject = autocompleteDestinationRef.current.getPlace();
    const addressDestinationName = addressDestinationObject.name;

    // Check if address is valid
    if (addressDestinationName) {
      setState(prevState => ({...prevState, adresseArrivee: addressDestinationName}))
    }
  }

  const handleDateChange = (date) => {
    const convertedDate = date.format('DD/MM/YYYY');
    setState(prevState => ({...prevState, date: convertedDate, displayedDate: date}))
  }

  const handleTimeChange = (date) => {
    const convertedTime = moment(date).format('HH:mm');
    setState(prevState => ({...prevState, heure: convertedTime}))
  }

  const handleReservation = (event) => {
    event.preventDefault();
    
    if (adresseDepart === '') {
      setState(prevState => ({...prevState, adresseDepartError: bookingForm.departError}))
    } else {
      setState(prevState => ({...prevState, adresseDepartError: ''}))
    }

    if (adresseArrivee === '') {
      setState(prevState => ({...prevState, adresseArriveeError: bookingForm.departError}))
    } else {
      setState(prevState => ({...prevState, adresseArriveeError: ''}))
    }

    if (date === '' || heure === '') {
      setState(prevState => ({...prevState, dateError: bookingForm.dateError}))
    } else {
      setState(prevState => ({...prevState, dateError: ''}))
    }

    if (typeVehicule === '') {
      setState(prevState => ({...prevState, typeVehiculeError: bookingForm.vehicleError}))
    } else {
      setState(prevState => ({...prevState, typeVehiculeError: ''}))
    }

    if (nombrePassagers === '') {
      setState(prevState => ({...prevState, nombrePassagersError: bookingForm.passengersError}))
    } else {
      setState(prevState => ({...prevState, nombrePassagersError: ''}))
    }

    if (nombreBagages23 === '' && nombreBagages10 === '') {
      setState(prevState => ({...prevState, nombreBagagesError: bookingForm.luggagesError}))
    } else {
      setState(prevState => ({...prevState, nombreBagagesError: ''}))
    }

    if (nomPassager === '') {
      setState(prevState => ({...prevState, nomPassagerError: bookingForm.nameError}))
    } else {
      setState(prevState => ({...prevState, nomPassagerError: ''}))
    }

    const emailRegEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (emailClient === '' || emailClient.search(emailRegEx) === -1) {
      setState(prevState => ({...prevState, emailClientError: bookingForm.emailError}))
    } else {
      setState(prevState => ({...prevState, emailClientError: ''}))
    }

    if (telephone === '') {
      setState(prevState => ({...prevState, telephoneError: bookingForm.telError}))
    } else {
      setState(prevState => ({...prevState, telephoneError: ''}))
    }

    if( adresseDepart !== '' && 
        adresseArrivee !== '' &&
        date !== '' &&
        heure !== '' &&
        typeVehicule !== '' &&
        nombrePassagers !== '' &&
        !(nombreBagages23 === '' && nombreBagages10 === '') &&
        nomPassager !== '' &&
        emailClient !== '' &&
        emailClient.search(emailRegEx) !== -1 &&
        telephone !== ''
      ){
        //Toggle spinner
        openBookingConfirmationPopUp();
        setIsSendingBookingRequest(true);

        let templateParams = {
          type_vehicule: typeVehicule,
          id_reservation: moment().format("YYYYMMDDHHmm"),
          _date: date,
          heure: heure,
          adresse_depart: adresseDepart,
          adresse_arrivee: adresseArrivee,
          id_vol: idVol,
          nombre_passagers: nombrePassagers,
          nombre_enfants: nombreEnfants,
          poids_enfants: poidsEnfants,
          nombre_bagages23: nombreBagages23,
          nombre_bagages10: nombreBagages10,
          nom_passager: nomPassager,
          email_client: emailClient,
          carte_bancaire: carteBancaire !== '' ? carteBancaire : 'Non renseigné',
          telephone: telephone,
          hotel: hotel,
          commentaires: commentaires
        };
        //Specify webservice below
        emailjs.send(
          emailConfig.EMAIL_SERVICE_ID, 
          emailConfig.EMAIL_TEMPLATE_BOOKING_ID, 
          templateParams, 
          emailConfig.EMAIL_SERVICE_KEY
        )
        .then(response => {
          // setResData(response.text); // For debugging
          handleReduceForm();
          resetFields();
          // Clear radio buttons
          let ele = document.getElementsByName("typeVehicule");
          for(let i=0;i<ele.length;i++)
          ele[i].checked = false;
        
          setIsBookingRequestSuccessful(true);
          setIsSendingBookingRequest(false)
        })
        .catch((error) => {
          console.log(error.response);
          setIsBookingRequestSuccessful(false);
          setIsSendingBookingRequest(false)
        })
    } else {
      // setResData('Erreur formulaire') // For debugging
    }
  }

  return (
    <div id="bookingForm" className="Section DesktopSection" >
      <div className="BookingSectionContained">
        <div id="FormBorder" className={isBookingFormOpen ? 'FormBorder Activated':'FormBorder'} onClick={handleFocusOnForm}>
          <form id="booking-form" className={isBookingFormOpen ? 'booking-form-active':'booking-form'} onSubmit={handleReservation} >
            
            <input type="hidden" name="idReservation" aria-label="idReservation"/>
            
            <div className="formPlaces">
              <div className="formPlacesDiv">
                <label className="yobaleFormLabel" htmlFor="departTextField">{bookingForm.depart}</label>
                <input 
                  id="departTextField"
                  className="formPlaceInput" 
                  type="text" 
                  name="adresseDepart" 
                  value={adresseDepart} 
                  onChange={handleChange}
                  placeholder=''
                />
                <p className={isBookingFormOpen ? 'formError':'formErrorHidden'} >{adresseDepartError}</p>
              </div>
              <div className="formPlacesDiv">
                <label className="yobaleFormLabel" htmlFor="destinationTextField">{bookingForm.destination}</label>
                <input 
                  id="destinationTextField"
                  className="formPlaceInput" 
                  type="text" 
                  name="adresseArrivee" 
                  value={adresseArrivee} 
                  onChange={handleChange} 
                  placeholder=''
                />
                <p className={isBookingFormOpen ? 'formError':'formErrorHidden'} >{adresseArriveeError}</p>
              </div>
              <div className={isBookingFormOpen ? 'formFieldsToDisplay':'formFieldsToHide'} >
                <div className="formDateTimeErrorContainer">
                  <div className="formDateTimeContainer">
                    <div className="formPlacesDiv formDateContainer">
                      <label className="yobaleFormLabel" htmlFor="date">{bookingForm.date}</label>
                      <Datetime 
                        inputProps={{ name:"date", className:"dateTimeInput", autoComplete:"off", readOnly: true, id:"date" }} 
                        dateFormat="YYYY/MM/DD" 
                        timeFormat={false} 
                        value={displayedDate}
                        onChange={handleDateChange}
                        closeOnSelect={true}
                        closeOnTab={true}
                        isValidDate={validDates}
                        locale={lang}
                      />
                    </div>
                    <div className="formPlacesDiv formTimePicker">
                      <label className="yobaleFormLabel" htmlFor="heure">{bookingForm.time}</label>
                      <Datetime 
                        inputProps={{ name:"heure", className:"dateTimeInput", id:"heure", type:"time", min:"00:00", max:"23:59" }} 
                        timeFormat="HH:mm"
                        initialViewDate={"12:00"}
                        timeConstraints={{ minutes: { step: 5 }}}
                        defaultValue={heure}
                        dateFormat={false} 
                        onChange={handleTimeChange}
                        closeOnSelect={true}
                        closeOnTab={true}
                        input={true}
                      />
                    </div>
                  </div>
                  <p className={isBookingFormOpen ? 'formError':'formErrorHidden'} >{dateError}</p>
                </div>
              </div>
            </div>
            
            <div className={isBookingFormOpen ? 'formFieldsToDisplay':'formFieldsToHide'} >

            <div className='formRow'>
              <div className="yobaleFormLabel voirTarifButton" onClick={() => openPricingPopUp()} >
                {bookingForm.tarifs}
                <GreenArrow />
              </div>
            </div>
            
              <div className="formRow formRowFlight">
                <label className="yobaleFormLabel" htmlFor="idVol">{bookingForm.flight}</label>
                <input 
                  type="text" 
                  name="idVol" 
                  id="idVol" 
                  value={idVol} 
                  onChange={handleChange} 
                />
              </div>
              
              <div className="formRow">
                <div className="yobaleFormLabel">{bookingForm.vehicleType}</div>
                <div className="formVehicleSelection">
                  <div className="formRadioContainer">
                    <input type="radio" id="berline" name="typeVehicule" value="berline" onChange={handleChange} />
                    <label className="formRadioLabel" htmlFor="berline">{bookingForm.sedan}</label>
                  </div>
                  <div className="formRadioContainer">
                    <input type="radio" id="monospace" name="typeVehicule" value="monospace" onChange={handleChange} />
                    <label className="formRadioLabel" htmlFor="monospace">{bookingForm.minivan}</label>
                  </div>
                  <p className={isBookingFormOpen ? 'formErrorVehicule':'formErrorVehiculeHidden'} >{typeVehiculeError}</p>
                </div>
              </div>

              <div className="formRowInLine">
                <label className="yobaleFormLabel" htmlFor="nombrePassagers">{bookingForm.passengers}</label>
                <select name="nombrePassagers" id="nombrePassagers" value={nombrePassagers} onChange={handleChange}>
                  <option value=""></option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                </select>
                <p className={isBookingFormOpen ? 'formError':'formErrorHidden'} >{nombrePassagersError}</p>
              </div>

              <div className="formRowInLine">
                <label className="yobaleFormLabel" htmlFor="nombreEnfants">{bookingForm.babies}</label>
                <div className="formSelectContainer">
                  <select name="nombreEnfants" id="nombreEnfants" value={nombreEnfants} onChange={handleChange}>
                    <option value=""></option>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </div>
                  <div className="formRow formRowEnfants">
                    <label className="yobaleFormLabel" htmlFor="poidsEnfants">{bookingForm.babiesWeight}</label>
                    <textarea 
                      type="text" 
                      className="formTextArea" 
                      name="poidsEnfants" 
                      id="poidsEnfants" 
                      value={poidsEnfants} 
                      onChange={handleChange}
                    ></textarea>
                  </div>
              </div>

              <div className="formRow">
                <div className="yobaleFormLabel">{bookingForm.luggages}</div>
                <div className="formRowInLine formRowInLineBagage">
                  <label className="yobaleFormLabel" htmlFor="nombreBagages23">{bookingForm.lug23}</label>
                  <select name="nombreBagages23" id="nombreBagages23" value={nombreBagages23} onChange={handleChange}>
                    <option value=""></option>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                  <label className="yobaleFormLabel" htmlFor="nombreBagages10">{bookingForm.lug10}</label>
                  <select name="nombreBagages10" id="nombreBagages10" value={nombreBagages10} onChange={handleChange}>
                    <option value=""></option>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                  <p className={isBookingFormOpen ? 'formError':'formErrorHidden'} >{nombreBagagesError}</p>
                </div>
              </div>
              
              <div className="formRow">
                <label className="yobaleFormLabel" htmlFor="nomPassager">{bookingForm.name}</label>
                <input type="text" name="nomPassager" id="nomPassager" value={nomPassager} onChange={handleChange} />
                <p className={isBookingFormOpen ? 'formError':'formErrorHidden'} >{nomPassagerError}</p>
              </div>
              
              <div className="formRow">
                <div className="formEmailContainer">
                  <div className="formEmailLeftContainer">
                    <label className="yobaleFormLabel" htmlFor="emailClient">{bookingForm.email}</label>
                    <input type="email" name="emailClient" id="emailClient" value={emailClient} onChange={handleChange} />
                    <p className={isBookingFormOpen ? 'formError':'formErrorHidden'} >{emailClientError}</p>
                  </div>
                </div>
              </div>

              {/* <div className="formRowInLine">
                <label className="yobaleFormLabel">Souhaitez-vous payer par carte bancaire à bord du véhicule ?</label>
                <select name="carte_bancaire" id="carte_bancaire" value={carte_bancaire} onChange={e => setCarte_bancaire(e.target.value)}>
                  <option value=""></option>
                  <option value="oui">Oui</option>
                  <option value="non">Non</option>
                </select>
              </div> */}
              
              <div className="formRow">
                <label className="yobaleFormLabel" htmlFor="telephone">{bookingForm.tel}</label>
                <input type="text" name="telephone" id="telephone" value={telephone} onChange={handleChange} />
                <p className={isBookingFormOpen ? 'formError':'formErrorHidden'} >{telephoneError}</p>
              </div>
              
              <div className="formRow">
                <label className="yobaleFormLabel" htmlFor="hotel">{bookingForm.company}</label>
                <input type="text" name="hotel" id="hotel" value={hotel} onChange={handleChange} />
              </div>
              
              <div className="formRow">
                <label className="yobaleFormLabel" htmlFor="commentaires">{bookingForm.message}</label>
                <textarea type="text" className="formTextArea" name="commentaires" id="commentaires" value={commentaires} onChange={handleChange}></textarea>
              </div>
              
              <input type="submit" className="RouteCTAButton formValidateButton" value={bookingForm.button} />
              
              <div className="formBottomRow">
                <div className="obligatoires">{bookingForm.information}</div>
                <div className="formCloseButtonContainer">
                  <svg className="formCloseButton" viewBox="0 0 48 48" fill="none" focusable="false" aria-hidden="true" onClick={handleReduceForm} >
                    <path d="M40.6 31.075l-15.7-15.7c-.5-.5-1.3-.5-1.8 0l-15.7 15.7c-.3.2-.4.5-.4.9s.1.7.4.9c.2.2.6.4.9.4.3 0 .7-.1.9-.4l14.8-14.7 14.7 14.7c.2.2.6.4.9.4.4 0 .7-.1.9-.4.2-.2.4-.6.4-.9.1-.4-.1-.7-.3-.9z" fill="currentColor"></path>
                  </svg>
                </div>
              </div>
            
            </div>
          </form>
        </div>
        <div className="ReviewsContainer">
          <TripAdvisorRating/>
          <GoogleMapsRating/>
        </div>
      </div>
    </div>
  )
}
export default BookingForm;