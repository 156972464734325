import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { LangContext } from '../index';
import { pagesConfig, helmetPages } from '../../config/config';
import useHelmet from '../../components/Hooks/useHelmet';
import Home from '../../pages/Home';
import ServiceAeroports from '../../pages/ServiceAeroports';
import ServiceUrbains from '../../pages/ServiceUrbains';
import ServiceLongueDuree from '../../pages/ServiceLongueDuree';

const CustomRouter = () => {
  const { lang } = useContext(LangContext);
  const helmet = useHelmet(helmetPages?.HOME);
  return(
    <Router path="/">
      {helmet}
      <Routes>
        <Route path={pagesConfig[`FR_HOME`]} element={<Home />} />
        <Route path={pagesConfig[`${lang.toUpperCase()}_SERVICE_AIRPORTS`]} element={<ServiceAeroports />} />
        <Route path={pagesConfig[`${lang.toUpperCase()}_SERVICE_CITY`]} element={<ServiceUrbains />} />
        <Route path={pagesConfig[`${lang.toUpperCase()}_SERVICE_LONG`]} element={<ServiceLongueDuree />} />
      </Routes>
    </Router>
  )
}
export default CustomRouter;