import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { 
  Button,
  DisplayContext, 
  useIsHomePage, 
  useTranslation 
} from "../../index";
import { delaysConfig, pagesConfig } from "../../../config/config";

const BookingButton = (props) => {
  const { handleBookingFormFocusAlways } = useContext(DisplayContext);
  const navigate = useNavigate();
	const isHomePage = useIsHomePage();
  const buttonsText = useTranslation("buttonsText");
  const {width, fontSize} = props;
  
  const handleClick = () => {
    if (!isHomePage) {
      navigate(pagesConfig[`FR_HOME`]);
      setTimeout(() => {
        handleBookingFormFocusAlways()
      }, delaysConfig?.BACK_TO_HOMEPAGE_BOOKING_FORM_DELAY)
    } else {
      handleBookingFormFocusAlways()
    }
  }
  
  return (
    <Button 
      action={handleClick}
      text={buttonsText?.booking}
      width={width}
      fontSize={fontSize}
    />
  )
}
export default BookingButton;