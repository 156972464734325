import { useContext } from "react"
import { LangContext } from "../index"
import { Helmet } from "react-helmet";
import metadata from "../../metadata/metadata.json"

const useHelmet = (page) => {
  const { lang } = useContext(LangContext);
  const pageMetadata = metadata?.[lang]?.[page]
  return (
    <Helmet>
      <title>{pageMetadata?.title}</title>
      <meta name="description" content={pageMetadata?.description} />
      <meta property="og:title" content={pageMetadata?.title} />
      <meta property="og:description" content={pageMetadata?.description} />
    </Helmet>
  )
}
export default useHelmet;