const routard = require('../images/externals/routard.png');
const routardWebp = require('../images/externals/routard.webp');
const fute = require('../images/externals/fute.png');
const futeWebp = require('../images/externals/fute.webp');
const auSenegal = require('../images/externals/au-senegal.png');
const auSenegalWebp = require('../images/externals/au-senegal.webp');
const lonelyPlanet = require('../images/externals/lonely.png');
const lonelyPlanetWebp = require('../images/externals/lonely.webp');

export const externalLinks = [
  {
    url: 'https://www.routard.com/guide/code_dest/senegal.htm',
    name: 'Guide du Routard',
    alt: 'routard-senegal',
    image: routard,
    webp: routardWebp,
  },
  {
    url: 'https://www.petitfute.com/p108-senegal/',
    name: 'Petit Fute',
    alt: 'petut-fute-senegal',
    image: fute,
    webp: futeWebp,
  },
  {
    url: 'https://www.au-senegal.com/conseils-pratiques-pour-venir-au-senegal,3602.html',
    name: 'Au Sénégal',
    alt: 'au-senegal.com',
    image: auSenegal,
    webp: auSenegalWebp,
  },
  {
    url: 'https://www.lonelyplanet.com/senegal',
    name: 'Lonely Planet',
    alt: 'lonely-planet-senegal',
    image: lonelyPlanet,
    webp: lonelyPlanetWebp,
  },
];
