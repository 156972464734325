import React from 'react';
import {
	BookingConfirmation,
	BookingForm,
	Concept,
	ContactFormulaire,
	Faq,
	Footer, 
	Header,
	Presentation,
	PricingPopUp,
	Reviews,
	Routes,
	Services,
	Team,
	Vehicules,
	WhatsappWidget,
} from '../components/index'

const Home = () => {
	return (
		<main className="Main">
			<WhatsappWidget />
			<Header	/>
			<Presentation	/>
			<BookingForm />
			<div className="MainSectionsContainer">
				<Services />
				<Routes />
				<Concept />
				<Reviews />
				<Vehicules />
				<Team />
				<Faq />
			</div>
			<Footer />
			<ContactFormulaire />
			<BookingConfirmation />
			<PricingPopUp	/>
		</main>
	)
}
export default Home;