import React, { useContext } from 'react';
import {
  DisplayContext,
  ImageFormatter,
  TableauTarifs,
  useTranslation,
} from '../../index';

const hybrid = require('../../../images/percs/service-transport-propre-senegal.png');
const hybridWebp = require('../../../images/percs/service-transport-propre-senegal.webp');
const wifi = require('../../../images/percs/wifi-taxi-prive-yobale.png');
const wifiWebp = require('../../../images/percs/wifi-taxi-prive-yobale.webp');
const toll = require('../../../images/percs/peage-compris-taxi-prive.png');
const tollWebp = require('../../../images/percs/peage-compris-taxi-prive.webp');
const charger = require('../../../images/percs/chargeurs-telephone-taxi-prive.png');
const chargerWebp = require('../../../images/percs/chargeurs-telephone-taxi-prive.webp');
const gas = require('../../../images/percs/carburant-compris-taxi-prive.png');
const gasWebp = require('../../../images/percs/carburant-compris-taxi-prive.webp');
const bottle = require('../../../images/percs/bouteille-eau-VTC.png');
const bottleWebp = require('../../../images/percs/bouteille-eau-VTC.webp');
const coffreImage = require('../../../images/vh/yobale-transport-bagages-aeroport-dakar.jpg');
const coffreImageWebp = require('../../../images/vh/yobale-transport-bagages-aeroport-dakar.webp');

const Tarifs = () => {
  const { toggleContactForm, handleBookingFormFocusAlways } =
    useContext(DisplayContext);
  const tarifsSectionText = useTranslation('tarifsSection');

  return (
    <div className="Section">
      <div className="SectionContained">
        <div className="TarifsTop">
          <div className="SectionTitle" id="goToTarifs">
            {tarifsSectionText.title}
          </div>
          <div className="TarifsSubTitle">
            <h2 className="SectionSubTitle">{tarifsSectionText.subtitle1}</h2>
            <div className="TarifsIconsContainer">
              <div className="TarifsIconsContainerColumn">
                <div className="TarifsIconsRow">
                  <ImageFormatter
                    className="TarifsIcons"
                    webpImage={hybridWebp}
                    image={hybrid}
                    alt="service-transport-propre-senegal"
                  />
                  <p className="TarifsIconsPhrase">{tarifsSectionText.item1}</p>
                </div>
                <div className="TarifsIconsRow">
                  <ImageFormatter
                    className={'TarifsIcons'}
                    webpImage={gasWebp}
                    image={gas}
                    alt="carburant-compris-taxi-prive"
                  />
                  <p className="TarifsIconsPhrase">{tarifsSectionText.item2}</p>
                </div>
              </div>
              <div className="TarifsIconsContainerColumn">
                <div className="TarifsIconsRow">
                  <ImageFormatter
                    className={'TarifsIcons'}
                    webpImage={tollWebp}
                    image={toll}
                    alt="peage-compris-taxi-prive"
                  />
                  <p className="TarifsIconsPhrase">{tarifsSectionText.item3}</p>
                </div>
                <div className="TarifsIconsRow">
                  <ImageFormatter
                    className={'TarifsIcons'}
                    webpImage={wifiWebp}
                    image={wifi}
                    alt="wifi-taxi-prive-yobale"
                  />
                  <p className="TarifsIconsPhrase">{tarifsSectionText.item4}</p>
                </div>
              </div>
              <div className="TarifsIconsContainerColumn">
                <div className="TarifsIconsRow">
                  <ImageFormatter
                    className={'TarifsIcons'}
                    webpImage={bottleWebp}
                    image={bottle}
                    alt="bouteille-eau-VTC"
                  />
                  <p className="TarifsIconsPhrase">{tarifsSectionText.item5}</p>
                </div>
                <div className="TarifsIconsRow">
                  <ImageFormatter
                    className={'TarifsIcons'}
                    webpImage={chargerWebp}
                    image={charger}
                    alt="chargeurs-telephone-taxi-prive"
                  />
                  <p className="TarifsIconsPhrase">{tarifsSectionText.item6}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="TarifsMain">
          <div className="TarifsDestinationBlock">
            <div className="TarifsContainerTitleRow">
              <div className="TarifsContainerTitleLeft">
                <h2 className="SectionSubTitle">
                  {tarifsSectionText.subtitle2}
                </h2>
              </div>
            </div>
            <div className="TarifsContainer">
              <TableauTarifs />
            </div>
            <div className="TarifsDetails">
              {'*Saly, Mbour, Somone, Ngaparou.'}
            </div>
            <div className="TarifsDetails">
              {'** Joal Fadiouth, Fimela, Ndangane, Palmarin.'}
            </div>
          </div>
          <p className="TarifsPhrase">
            {tarifsSectionText.linkPhrase1}&#160;
            <span
              className="TarifsButtonLink"
              onClick={handleBookingFormFocusAlways}
            >
              {tarifsSectionText.linkPhrase2}
            </span>
            &#160;{tarifsSectionText.linkPhrase3}&#160;
            <span className="TarifsButtonLink" onClick={toggleContactForm}>
              {tarifsSectionText.linkPhrase4}
            </span>
            &#160;{tarifsSectionText.linkPhrase5}
          </p>
          <div className="TarifsCoffreBlock">
            <div className="TarifsCoffreRight">
              <div className="TarifsContainer">
                <div>
                  <div className="TarifsContainerRow">
                    <div className="TarifsColumnItemDescription">
                      <div>{tarifsSectionText.option1}</div>
                    </div>
                    <div className="TarifsColumnItemPrice">
                      <div>{tarifsSectionText.option2}5&#160;000 FCFA</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <ImageFormatter
                className={'coffreImage'}
                webpImage={coffreImageWebp}
                image={coffreImage}
                alt="coffre-bagages-navette-aeroport-dakar-DSS"
                width={'1440'}
                height={'996'}
              />
            </div>
          </div>
          <div className="TarifsDetails">{tarifsSectionText.information}</div>
        </div>
      </div>
    </div>
  );
};
export default Tarifs;
