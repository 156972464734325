import React, { useContext } from 'react';
import {
  Arguments, 
  DisplayContext,
} from '../../index';

const Concept = () => {
  const { handleLoseFocus } = useContext(DisplayContext);
  return (
    <div className="Section" id="goToConcept" onClick={handleLoseFocus}>
      <div className="SectionContained ConceptSection">
        <Arguments />
      </div>
    </div>
  )
}
export default Concept;