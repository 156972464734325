import React, { useContext } from 'react';
import {
  DisplayContext,
  LoadingConfirmation,
  ImageFormatter,
  useTranslation,
} from '../../../index';

const phoneLogo = require('../../../../images/call/reserver-VTC-transfert-aeroport-DSS-Dakar-Saly.png');
const phoneLogoWebp = require('../../../../images/call/reserver-VTC-transfert-aeroport-DSS-Dakar-Saly.webp');

const ContactConfirmation = () => {
  const {
    displayContactConfirmation,
    isContactRequestSuccessful,
    isSendingContactRequest,
    setDisplayContactConfirmation,
  } = useContext(DisplayContext);
  const contactConfirm = useTranslation('contactConfirm');
  return (
    <div
      className={
        displayContactConfirmation
          ? 'confirmationPopUp-active'
          : 'confirmationPopUp'
      }
    >
      {isSendingContactRequest ? (
        <LoadingConfirmation>
          <p className="contactConfirmationPhrase">{contactConfirm.loading}</p>
        </LoadingConfirmation>
      ) : isContactRequestSuccessful ? (
        <>
          <p className="contactConfirmationPhrase">
            {contactConfirm.confirmation}
          </p>
          <div
            className="RouteCTAButton formValidateButton"
            onClick={() => setDisplayContactConfirmation(false)}
          >
            {contactConfirm.closeButton}
          </div>
        </>
      ) : (
        <>
          <p className="contactConfirmationPhrase">{contactConfirm.error}</p>
          <p className="contactConfirmationPhrase">{contactConfirm.retry}</p>
          <div className="whatsappLogoAndNumber">
            <ImageFormatter
              className={'phoneLogo'}
              webpImage={phoneLogoWebp}
              image={phoneLogo}
              alt={'reserver-VTC-transfert-aeroport-DSS-Dakar-Saly'}
              width={'40'}
              height={'40'}
            />
            {contactConfirm.phone}
          </div>
          <div
            className="RouteCTAButton formValidateButton"
            onClick={() => setDisplayContactConfirmation(false)}
          >
            {contactConfirm.closeButton}
          </div>
        </>
      )}
    </div>
  );
};
export default ContactConfirmation;
