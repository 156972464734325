import { useContext } from "react";
import { 
  Button,
  DisplayContext, 
  useTranslation 
} from "../../index";

const ContactButton = (props) => {
  const { toggleContactForm } = useContext(DisplayContext);
  const buttonsText = useTranslation("buttonsText");
  const {width, fontSize} = props;
  return (
    <Button 
      action={toggleContactForm}
      text={buttonsText?.contact}
      width={width}
      fontSize={fontSize}
    />
  )
}
export default ContactButton;