import React from 'react';
import {
  ArgumentsServices, 
  useTranslation,
  BookingButton
} from '../../index';

const Services = () => {
  const servicesSection = useTranslation("servicesSection")
  return (
    <div className="Section">
      <div className="SectionContained">
        <h2 className="SectionTitle" id="goToServices">
          {servicesSection.title}
        </h2>
        <div className="ServicesArgsContainer">
          <ArgumentsServices />
          <div style={{display:"flex", justifyContent:"center"}}>
            <BookingButton width={"250px"}/>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Services;