export const goTo = (elementId) => {
	const element = document.getElementById(elementId);
	if (element === null) return;
	const offset = 100;
	const bodyRect = document.body.getBoundingClientRect().top;
	const elementRect = element.getBoundingClientRect().top;
	const elementPosition = elementRect - bodyRect;
	const offsetPosition = elementPosition - offset;

	window.scrollTo({
	  top: offsetPosition,
	  behavior: 'smooth'
	});
}

export const goToTop = () => {
	window.scrollTo({
		top: 0,
		behavior: 'smooth'
	})
}