import { ImageFormatter } from "../../index";

const DestinationCard = ({title, image, webpImage}) => {
  return (
    <div className="DestinationCardContainer" style={{borderRadius: "8px", backgroundColor: "white", border: "1px solid lightgrey"}} >
      <ImageFormatter
        image={image}
        webpImage={webpImage}
        alt={title}
        width={300}
        height={100}
        className={"DestinationCardImage"}
      />
      <h4 className="DestinationCardTitle">{title}</h4>
    </div>
  )
}
export default DestinationCard;