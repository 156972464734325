import React, { useContext } from 'react';
import {
  DisplayContext,
  LoadingConfirmation,
  ImageFormatter,
  useTranslation,
} from '../../../index';

const phoneLogo = require('../../../../images/call/reserver-VTC-transfert-aeroport-DSS-Dakar-Saly.png');
const phoneLogoWebp = require('../../../../images/call/reserver-VTC-transfert-aeroport-DSS-Dakar-Saly.webp');

const BookingConfirmation = () => {
  const {
    displayBookingFormConfirmation,
    isSendingBookingRequest,
    isBookingRequestSuccessful,
    closeBookingConfirmationPopUp,
  } = useContext(DisplayContext);
  const bookingConfirm = useTranslation('bookingConfirm');

  return (
    <div
      className={
        displayBookingFormConfirmation
          ? 'confirmationPopUp-active'
          : 'confirmationPopUp'
      }
    >
      {isSendingBookingRequest ? (
        <LoadingConfirmation>
          <p className="confirmationPhrase">{bookingConfirm.loading}</p>
        </LoadingConfirmation>
      ) : isBookingRequestSuccessful ? (
        <>
          <p className="confirmationPhrase">{bookingConfirm.succeed1}</p>
          <p className="confirmationPhrase">{bookingConfirm.succeed2}</p>
          <p className="confirmationPhrase">{bookingConfirm.succeed3}</p>
          <div
            className="RouteCTAButton formValidateButton"
            onClick={closeBookingConfirmationPopUp}
          >
            {bookingConfirm.closeButton}
          </div>
        </>
      ) : (
        <>
          <p className="confirmationPhrase">{bookingConfirm.error1}</p>
          <p className="confirmationPhrase">{bookingConfirm.error2}</p>
          <div className="whatsappLogoAndNumber">
            <ImageFormatter
              className={'phoneLogo'}
              containerClass={'phoneLogoContainer'}
              webpImage={phoneLogoWebp}
              image={phoneLogo}
              alt={'reserver-VTC-transfert-aeroport-DSS-Dakar-Saly'}
              width={'40'}
              height={'40'}
            />
            {bookingConfirm.error3}
          </div>
          <div
            className="RouteCTAButton formValidateButton"
            onClick={() => closeBookingConfirmationPopUp(true)}
          >
            {bookingConfirm.closeButton}
          </div>
        </>
      )}
    </div>
  );
};
export default BookingConfirmation;
