import React from 'react';
import {
	ContactFormulaire,
	Footer, 
	Header,
	ServicePage,
	WhatsappWidget,
} from '../components/index'
import useHelmet from '../components/Hooks/useHelmet';
import { helmetPages } from '../config/config';

const airportDakarSenegal = require('../images/pages/services/airport-dakar-senegal.jpg')
const airportDakarSenegalWebp = require('../images/pages/services/airport-dakar-senegal.webp')
const accueilAeroport = require('../images/team/yobale-accueil-aeroport-dakar.jpg')
const accueilAeroportWebp = require('../images/team/yobale-accueil-aeroport-dakar.webp')

const ServiceAeroports = () => {
	const helmet = useHelmet(helmetPages?.AIRPORT);
	return (
		<main className="Main">
			{helmet}
			<WhatsappWidget />
			<Header	/>
			<div className="MainSectionsContainer">
				<ServicePage 
					pageText="airportServiceText"
					image1={airportDakarSenegal}
					image1Webp={airportDakarSenegalWebp}
					image2={accueilAeroport}
					image2Wepb={accueilAeroportWebp}
					hotelList={true}
					airport={true}
				/>
			</div>
			<Footer />
			<ContactFormulaire />
		</main>
	)
}
export default ServiceAeroports;