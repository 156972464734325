import * as RadixAccordion from '@radix-ui/react-accordion';
import { ChevronDownIcon } from '@radix-ui/react-icons';

const Accordion = ({text}) => {
  return (
    <RadixAccordion.Root className="AccordionRoot" type="multiple">
      {text.map((faq, index) => (
        <RadixAccordion.Item className="AccordionItem" value={`item-${index + 1}`} key={index}>
          <RadixAccordion.Header className="AccordionHeader">
            <RadixAccordion.Trigger className="AccordionTrigger">
              <h3 className="AccordionTriggerText">{faq.question}</h3>
              <ChevronDownIcon className="AccordionChevron" aria-hidden />
            </RadixAccordion.Trigger>
          </RadixAccordion.Header>
          <RadixAccordion.Content className="AccordionContent">
            <div className="AccordionContentText">
              {faq.answer}
            </div>
          </RadixAccordion.Content>
        </RadixAccordion.Item>
      ))}
    </RadixAccordion.Root>
  );
}

export default Accordion;