import React from 'react';
import {Spinner} from '../../../index';

const LoadingConfirmation = (props) => {
  return (
    <div className="confirmationWithSpinner">
      {props.children}
      <Spinner />
    </div>
  )
}
export default LoadingConfirmation;