import React, { useContext } from 'react';
import { DisplayContext, ImageFormatter, useTranslation } from '../../index';
import { whatsappConfig } from '../../../config/config';

const callImage = require('../../../images/call/reserver-transfert-aeroport-DSS-hotel.png');
const callImageWebp = require('../../../images/call/reserver-transfert-aeroport-DSS-hotel.webp');
const phoneLogo = require('../../../images/call/reserver-VTC-transfert-aeroport-DSS-Dakar-Saly.png');
const phoneLogoWebp = require('../../../images/call/reserver-VTC-transfert-aeroport-DSS-Dakar-Saly.webp');

const Call = () => {
  const { toggleContactForm, handleBookingFormFocusAlways } =
    useContext(DisplayContext);
  const callText = useTranslation('callSection');
  const buttonsText = useTranslation('buttonsText');

  return (
    <div className="Section">
      <div className="SectionContained">
        <div className="CallContainer">
          <div className="CallColumn CallColumn1">
            <ImageFormatter
              className={'callImage'}
              webpImage={callImageWebp}
              image={callImage}
              alt={'reserver-transfert-aeroport-DSS-hotel'}
              width={566}
              height={365}
            />
          </div>
          <div className="CallColumn CallColumn2">
            <div className="CallColumnTitle">{callText.title}</div>
            <div className="CallColumnDescription">{callText.subTitle1}</div>
            <div className="CallButtonContainer">
              <div
                className="PresentationButton PresentationResaButton"
                onClick={handleBookingFormFocusAlways}
              >
                {buttonsText.booking}
              </div>
              <div className="contactButton" onClick={toggleContactForm}>
                {buttonsText.contact}
              </div>
            </div>
            <div className="CallColumnDescription">{callText.subTitle2}</div>
            <div className="CallButtonContainer">
              <a
                className="callWhatsappButton"
                href={
                  whatsappConfig.WHATSAPP_URL +
                  whatsappConfig.WHATSAPP_COMPANY_NUMBER
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <ImageFormatter
                  className={'phoneLogo'}
                  webpImage={phoneLogoWebp}
                  image={phoneLogo}
                  alt={'reserver-VTC-transfert-aeroport-DSS-Dakar-Saly'}
                  width={'40'}
                  height={'40'}
                  containerClass={'phoneLogoContainer'}
                />
                {buttonsText.whatsapp}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Call;
