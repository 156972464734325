import React from 'react';
import {
	ServicePage,
	ContactFormulaire,
	Footer, 
	Header,
	WhatsappWidget,
} from '../components/index'
import useHelmet from '../components/Hooks/useHelmet';
import { helmetPages } from '../config/config';

const cityToCitySenegal = require('../images/pages/services/city-to-city-senegal.jpg')
const cityToCitySenegalWebp = require('../images/pages/services/city-to-city-senegal.webp')
const cityToCityDakar = require('../images/pages/services/city-to-city-dakar.jpg')
const cityToCityDakarWebp = require('../images/pages/services/city-to-city-dakar.webp')

const ServiceUrbains = () => {
	const helmet = useHelmet(helmetPages?.CITY);
	return (
		<main className="Main">
			{helmet}
			<WhatsappWidget />
			<Header	/>
			<div className="MainSectionsContainer">
				<ServicePage 
					pageText="urbanServiceText"
					image1={cityToCitySenegal}
					image1Webp={cityToCitySenegalWebp}
					image2={cityToCityDakar}
					image2Wepb={cityToCityDakarWebp}
				/>
			</div>
			<Footer />
			<ContactFormulaire />
		</main>
	)
}
export default ServiceUrbains;