import React, { useState } from 'react';

const Select = ({
  value,
  onValueChange,
  possibleValues,
  displayedValues,
  ariaLabel,
  fixedTitle,
  customTriggerClass,
  customSelectClass,
  customSelectContentClass,
  langFlag,
}) => {
  const [open, setOpen] = useState(false);
  const handleClick = (index) => {
    onValueChange(possibleValues[index])
  }

  return (
    <div
      aria-label={ariaLabel}
      className={customTriggerClass ?? 'SelectTrigger'}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      {langFlag && langFlag(value)}
      {fixedTitle && fixedTitle.length > 0 ? (
        fixedTitle
      ) : (
        <div aria-label={value}>{value}</div>
      )}
      {open && (
        <div className={customSelectContentClass ?? 'SelectContent'}>
          {displayedValues.map((value, index) => {
            return (
              <div 
                key={value} 
                className={customSelectClass ?? 'SelectItem'}
                onClick={() => handleClick(index)}
              >
                {langFlag && langFlag(value)}{value}
              </div>
            )
          })}
        </div>
      )}
    </div>
  );
}

export default Select;