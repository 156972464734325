import { 
  useTranslation, 
  DestinationCard 
} from '../../index';

const dakar = require('../../../images/destinations/dakar-senegal.png');
const dakarWebp = require('../../../images/destinations/dakar-senegal.webp');
const saly = require('../../../images/destinations/saly-beach-senegal.png');
const salyWebp = require('../../../images/destinations/saly-beach-senegal.webp');
const palmarin = require('../../../images/destinations/palmarin-senegal.png');
const palmarinWebp = require('../../../images/destinations/palmarin-senegal.webp');
const riuBaobab = require('../../../images/destinations/riu-baobab-saly.png');
const riuBaobabWebp = require('../../../images/destinations/riu-baobab-saly.webp');


const PopularDestinations = () => {
  const routesSectionText = useTranslation('routesSectionText');
  const destinations = [
    {
      title: 'Dakar',
      image: dakar,
      webpImage: dakarWebp
    },
    {
      title: 'Saly',
      image: saly,
      webpImage: salyWebp
    },
    {
      title: 'Hotel Riu Baobab',
      image: riuBaobab,
      webpImage: riuBaobabWebp
    },
    {
      title: 'Palmarin',
      image: palmarin,
      webpImage: palmarinWebp
    }
  ];
  
  return (
    <>
      <h3 className="SectionSubTitle3">
        {routesSectionText?.destinations}
      </h3>
      <div style={{display: "flex", flexWrap: "wrap", margin: "12px auto", gap: "16px", width:"100%", justifyContent:"center"}}>
        {destinations.map((item, index) => {
          return (
            <DestinationCard title={item.title} key={index} image={item.image} webpImage={item.webpImage} />
          )}
        )}
      </div>
    </>
  )
}
export default PopularDestinations;