import { LangContextProvider } from "./LangContext";
import { DisplayContextProvider } from "./DisplayContext";

const Providers = ({ children }) => {
  return(
    <LangContextProvider>
      <DisplayContextProvider>
        {children}
      </DisplayContextProvider>
    </LangContextProvider>
  )
}
export default Providers;