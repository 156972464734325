import React, { useContext } from 'react';
import { DisplayContext, TableauTarifs, useTranslation } from '../index';

const PricingPopUp = () => {
  const { isPricingPopUpOpen, closePricingPopUp, toggleContactForm } =
    useContext(DisplayContext);
  const pricingPopup = useTranslation('pricingPopup');
  return (
    <div
      className={isPricingPopUpOpen ? 'formulaire-popup-active' : 'formulaire'}
    >
      <div className="TarifsDestinationBlock">
        <div className="TarifsContainerTitleRow">
          <div className="SectionSubTitle SectionSubTitlePopup">
            {pricingPopup.title}
          </div>
          <div className="formulaireCloseButton" onClick={closePricingPopUp}>
            {' '}
            x{' '}
          </div>
        </div>
        <div className="TarifsContainer TarifsContainerPopup">
          <TableauTarifs />
        </div>
        <div className="TarifsDetails TarifsDetailsPopup">
          {pricingPopup.information1}
        </div>
        <div className="TarifsDetails TarifsDetailsPopup">
          {pricingPopup.information2}
        </div>

        <p className="TarifsPhrase TarifsPhrasePopup">
          {pricingPopup.contact1}&#160;
          <span className="TarifsButtonLink" onClick={closePricingPopUp}>
            {pricingPopup.contact2}
          </span>
          &#160;{pricingPopup.contact3}&#160;
          <span className="TarifsButtonLink" onClick={toggleContactForm}>
            {pricingPopup.contact4}
          </span>
          &#160;{pricingPopup.contact5}
        </p>

        <button
          className="RouteCTAButton formValidateButton"
          onClick={closePricingPopUp}
        >
          {pricingPopup.closeButton}
        </button>
      </div>
    </div>
  );
};
export default PricingPopUp;
