import ImageFormatter from "./Images/ImageFormatter";

const LinksDisplayer = ({ links }) => {
  return (
    <div className="externalLinksContainer">
      {links && links.map((link, index) => (
        <a className="externalLink" key={index} href={link.url} target="_blank" rel="noreferrer">
          <ImageFormatter
            className="externalLinkImage" 
            webpImage={link.webp} 
            image={link.image} 
            alt={link.alt} 
            width={200}
            height={100} 
          />
        </a>
      ))}
    </div>
  );
}
export default LinksDisplayer;