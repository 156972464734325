import React, { useContext } from 'react';
import {
	DisplayContext,
	useTranslation
} from '../../index';

const Presentation = () => {
	const {
		handleLoseFocus
	} = useContext(DisplayContext);
	const presentationSection = useTranslation("presentationSection");
	return (
		<div className="Presentation" onClick={handleLoseFocus}>
			<h1 className="PresentationTitle">{presentationSection.title}</h1>
		</div>
	)
}
export default Presentation;