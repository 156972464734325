import { useContext } from "react"
import { LangContext } from "../../index"

const RouteCard = ({props, text}) => {
  const { lang } = useContext(LangContext)
  const { from, to, priceFCFA, priceEUR, priceUSD } = props;
  const foreignPrice = lang === 'en' ? `US$ ${priceUSD}` : `${priceEUR}€`
  const displayedText = `${text} ${priceFCFA} FCFA (${foreignPrice})`;
  return (
    <div className="RouteCard">
      <h4 style={{margin: "0", padding:"0"}}>{from}</h4>
      <p style={{margin: "0", padding:"0"}}> &#x21c4; </p>
      <h4 style={{margin: "0", padding:"0"}}>{to}</h4>
      <p style={{padding: "14px 0 0 0", margin:"0", textAlign:"right", fontWeight:"600", color:"lightgrey", fontSize:"15px"}}>
        {displayedText}
      </p>
    </div>
  )
}
export default RouteCard;