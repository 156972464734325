import { useState, useEffect } from 'react';

const useDevice= () => {
  const [device, setDevice] = useState({'mobile':false, 'tablet':false, 'desktop':false});

  const handleResize = () => {
    if (window.innerWidth <= 700) {
      setDevice({'mobile':true, 'tablet':false, 'desktop':false});
    } else if (window.innerWidth <= 1280) {
      setDevice({'mobile':false, 'tablet':true, 'desktop':false});
    } else {
      setDevice({'mobile':false, 'tablet':false, 'desktop':true});
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return device;
}
export default useDevice;