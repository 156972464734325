import { useContext } from "react"
import { LangContext } from "../index"
import { langConfig } from "../../config/config"

const useIsHomePage = () => {
  const { lang } = useContext(LangContext)
  const homepageUrl = langConfig?.LANGS_URLS[lang];
  const pathName = window?.location?.pathname;
  return pathName === homepageUrl
}
export default useIsHomePage;