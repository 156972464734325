import { useTranslation, ImageFormatter } from '../../index';

const hybrid = require('../../../images/percs/service-transport-propre-senegal.png');
const hybridWebp = require('../../../images/percs/service-transport-propre-senegal.webp');
const wifi = require('../../../images/percs/wifi-taxi-prive-yobale.png');
const wifiWebp = require('../../../images/percs/wifi-taxi-prive-yobale.webp');
const toll = require('../../../images/percs/peage-compris-taxi-prive.png');
const tollWebp = require('../../../images/percs/peage-compris-taxi-prive.webp');
const charger = require('../../../images/percs/chargeurs-telephone-taxi-prive.png');
const chargerWebp = require('../../../images/percs/chargeurs-telephone-taxi-prive.webp');
const gas = require('../../../images/percs/carburant-compris-taxi-prive.png');
const gasWebp = require('../../../images/percs/carburant-compris-taxi-prive.webp');
const bottle = require('../../../images/percs/bouteille-eau-VTC.png');
const bottleWebp = require('../../../images/percs/bouteille-eau-VTC.webp');

const RoutePerks = () => {
  const routesSectionText = useTranslation('routesSectionText');

  return (
    <>
      <h2 className="SectionSubTitle3">{routesSectionText.subtitle1}</h2>
      <div className="TarifsIconsContainer">
        <div className="TarifsIconsRow">
          <ImageFormatter
            className="TarifsIcons"
            webpImage={hybridWebp}
            image={hybrid}
            alt="service-transport-propre-senegal"
          />
          <p className="TarifsIconsPhrase">{routesSectionText.item1}</p>
        </div>
        <div className="TarifsIconsRow">
          <ImageFormatter
            className={'TarifsIcons'}
            webpImage={gasWebp}
            image={gas}
            alt="carburant-compris-taxi-prive"
          />
          <p className="TarifsIconsPhrase">{routesSectionText.item2}</p>
        </div>
        <div className="TarifsIconsRow">
          <ImageFormatter
            className={'TarifsIcons'}
            webpImage={tollWebp}
            image={toll}
            alt="peage-compris-taxi-prive"
          />
          <p className="TarifsIconsPhrase">{routesSectionText.item3}</p>
        </div>
        <div className="TarifsIconsRow">
          <ImageFormatter
            className={'TarifsIcons'}
            webpImage={wifiWebp}
            image={wifi}
            alt="wifi-taxi-prive-yobale"
          />
          <p className="TarifsIconsPhrase">{routesSectionText.item4}</p>
        </div>
        <div className="TarifsIconsRow">
          <ImageFormatter
            className={'TarifsIcons'}
            webpImage={bottleWebp}
            image={bottle}
            alt="bouteille-eau-VTC"
          />
          <p className="TarifsIconsPhrase">{routesSectionText.item5}</p>
        </div>
        <div className="TarifsIconsRow">
          <ImageFormatter
            className={'TarifsIcons'}
            webpImage={chargerWebp}
            image={charger}
            alt="chargeurs-telephone-taxi-prive"
          />
          <p className="TarifsIconsPhrase">{routesSectionText.item6}</p>
        </div>
      </div>
    </>
  );
};
export default RoutePerks;
