import React, {useContext, useState} from 'react';
import emailjs from '@emailjs/browser';
import {emailConfig} from '../../../../config/config';
import { 
  ContactConfirmation, 
  DisplayContext,
  useTranslation
} from '../../../index';

const initialState = {
  nomFormulaire: '',
  nomFormulaireError: '',
  emailFormulaire: '',
  emailFormulaireError: '',
  telephoneFormulaire: '',
  messageFormulaire: '',
  messageFormulaireError: '',
  resDataContact: '',
}

const ContactFormulaire = () => {
  const {
    isContactFormOpen,
    setIsContactRequestSuccessful, 
    closeContactForm,
    openContactConfirmationPopUp,
    setIsSendingContactRequest
  } = useContext(DisplayContext);
  const contactForm = useTranslation("contactForm")
  const [{
    nomFormulaire,
    nomFormulaireError,
    emailFormulaire,
    emailFormulaireError,
    telephoneFormulaire,
    messageFormulaire,
    messageFormulaireError,
    // resDataContact, // For debugging
  }, setState] = useState(initialState);

  const resetFields = () => {
    setState({...initialState})
  }

  const handleChange = (event) => {
    const {name, value} = event.target
    setState(prevState => ({...prevState, [name]: value}))
  }

  const handleFormulaire = async (event) => {
    event.preventDefault()

    if (nomFormulaire === '') {
      setState(prevState => ({...prevState, nomFormulaireError: contactForm.nameError}))
    } else {
      setState(prevState => ({...prevState, nomFormulaireError: ''}))
    }

    if (emailFormulaire === '') {
      setState(prevState => ({...prevState, emailFormulaireError: contactForm.emailError}))
    } else {
      setState(prevState => ({...prevState, emailFormulaireError: ''}))
    }

    if (messageFormulaire === '') {
      setState(prevState => ({...prevState, messageFormulaireError: contactForm.messageError}))
    } else {
      setState(prevState => ({...prevState, messageFormulaireError: ''}))
    }

    if( 
      nomFormulaire !== '' && 
      emailFormulaire !== '' &&
      messageFormulaire !== ''
    ){
      // Open confirmation and toggle spinner
      setIsSendingContactRequest(true)
      openContactConfirmationPopUp();

      let templateParamsContact = {
        nomFormulaire: nomFormulaire,
        emailFormulaire: emailFormulaire,
        telephoneFormulaire: telephoneFormulaire,
        messageFormulaire: messageFormulaire
      };

      //Specify webservice below
      emailjs.send(
        emailConfig.EMAIL_SERVICE_ID, 
        emailConfig.EMAIL_TEMPLATE_CONTACT_ID, 
        templateParamsContact, 
        emailConfig.EMAIL_SERVICE_KEY
      )
      .then(response => {
        // setState(prevState => ({...prevState, resDataContact: response.text})) // For debugging
        resetFields()
        setIsContactRequestSuccessful(true)
        setIsSendingContactRequest(false)
      })  
      .catch(error => {
        setIsContactRequestSuccessful(false)
        setIsSendingContactRequest(false)
        console.log(error.response);
      })
    } else {
      // setState(prevState => ({...prevState, resDataContact: 'Erreur formulaire'})) // For debugging
    }
  }
  
  return (
    <div >
      <ContactConfirmation />
      <form id="formulaire" className={isContactFormOpen ? 'formulaire-active':'formulaire'} onSubmit={handleFormulaire} >
        <div className="formulaireCloseButton" onClick={closeContactForm} > x </div>
        <div className="formulaireContactTitre">{contactForm.title}</div>
        <label className="formulaireLabel" htmlFor="nomFormulaire">{contactForm.name}</label>
        <input 
          type="text" 
          name="nomFormulaire"
          id="nomFormulaire"
          onChange={handleChange} 
          value={nomFormulaire}
        />
        <p className={isContactFormOpen ? 'formContactError':'formContactErrorHidden'} >{nomFormulaireError}</p>
        <label className="formulaireLabel" htmlFor="emailFormulaire">{contactForm.email}</label>
        <input 
          type="email" 
          name="emailFormulaire"
          id="emailFormulaire" 
          onChange={handleChange}
          value={emailFormulaire}
        />
        <p className={isContactFormOpen ? 'formContactError':'formContactErrorHidden'} >{emailFormulaireError}</p>
        <label className="formulaireLabel" htmlFor="telephoneFormulaire">{contactForm.tel}</label>
        <input 
          type="text" 
          name="telephoneFormulaire"
          id="telephoneFormulaire" 
          onChange={handleChange}
          value={telephoneFormulaire}
        />
        <label className="formulaireLabel" htmlFor="messageFormulaire">{contactForm.message}</label>
        <textarea 
          type="text" 
          className="formulaireTextArea" 
          name="messageFormulaire"
          id="messageFormulaire"
          onChange={handleChange}
          value={messageFormulaire}
        ></textarea>
        <p className={isContactFormOpen ? 'formContactError':'formContactErrorHidden'} >{messageFormulaireError}</p>
        <input 
          type="submit" 
          className="RouteCTAButton formValidateButton" 
          value={contactForm.sendButton} 
        />
        <div className="obligatoires">{contactForm.information}</div>
      </form>
    </div>
  )
}
export default ContactFormulaire;