import React, { useContext } from 'react';
import { LangContext, usePricing, useTranslation } from '../../index';

const TableauTarifs = () => {
  const tableauTarifsText = useTranslation("tableauTarifs");
  const { lang } = useContext(LangContext);
  const pricing = usePricing();
  return (
    <div>
      {pricing.map((item, index) => {
        const foreignPrice = lang === 'en' ? `US$ ${item?.priceUSD}` : `${item?.priceEUR}€`
        return (
          <div className="TarifsContainerRow" key={index}>
            <div className="TarifsColumnItemDescription">
            <div>{`${item?.from} <> ${item?.to}`}</div>
            </div>
            <div className="TarifsColumnItemPrice">
              <div className="TarifsColumnItemPriceContainer">
                <div>{tableauTarifsText.option1}&#160;</div>
                <div>{item?.priceFCFA} FCFA</div>
              </div>
              <div>({foreignPrice})</div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
export default TableauTarifs;