import React from 'react';

const Spinner = ({ customStyles }) => {
  const defaultStyles = {
    border: '5px solid #f3f3f3',
    borderTop: '5px solid #006400',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    animation: 'spin 1s ease-in-out infinite',
  };

  return (
    <div style={{ ...defaultStyles, ...customStyles }} ></div>
  );
};
export default Spinner;