import React from 'react';
import { Providers, CustomRouter } from './components/index';
import './App.scss';

const App = () => {
  return (
    <Providers>
      <div className="App">
        <CustomRouter />
      </div>
    </Providers>
  );
};

export default App;
