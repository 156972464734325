import React from "react";
import {ImageProvider} from "../../index";

const webpImageDesktop = require('../../../images/vh/yobale-transfert-aeroport-hybride-desktop1x.webp')
const webpImageDesktop2x = require('../../../images/vh/yobale-transfert-aeroport-hybride-desktop2x.webp')
const webpImageTablet = require('../../../images/vh/yobale-transfert-aeroport-hybride-tablet1x.webp')
const webpImageTablet2x = require('../../../images/vh/yobale-transfert-aeroport-hybride-tablet2x.webp')
const webpImageMobile = require('../../../images/vh/yobale-transfert-aeroport-hybride-mobile1x.webp')
const webpImageMobile2x = require('../../../images/vh/yobale-transfert-aeroport-hybride-mobile2x.webp')
const imageDefault = require('../../../images/vh/yobale-transfert-aeroport-hybride.png')

const CarRight = ({ className, alt, containerClass }) => {
  return (
    <ImageProvider 
      className={className}
      webpImageDesktop={webpImageDesktop}
      webpImageDesktop2x={webpImageDesktop2x}
      webpImageTablet={webpImageTablet}
      webpImageTablet2x={webpImageTablet2x}
      webpImageMobile={webpImageMobile}
      webpImageMobile2x={webpImageMobile2x}
      imageDefault={imageDefault}
      alt={alt}
      containerClass={containerClass} 
      width={"1136"}
      height={"639"}
    />
  )
}
export default CarRight;