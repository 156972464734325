import { ImageFormatter, useTranslation } from '../../index';

const connecteLogo = require('../../../images/concept/reserver-VTC-senegal-transfert-aeroport-DSS.png');
const connecteLogoWebp = require('../../../images/concept/reserver-VTC-senegal-transfert-aeroport-DSS.webp');
const hautDeGammeLogo = require('../../../images/concept/VTC-confort-senegal.png');
const hautDeGammeLogoWebp = require('../../../images/concept/VTC-confort-senegal.webp');
const feuilleLogo = require('../../../images/concept/VTC-ecologiques-senegal.png');
const feuilleLogoWebp = require('../../../images/concept/VTC-ecologiques-senegal.webp');

const Arguments = () => {
  const argumentsSection = useTranslation('argumentsSection');
  return (
    <div className="Arguments">
      <div className="ArgumentsContainer">
        <div className="ArgumentsColumn ArgumentsColumn1">
          <ImageFormatter
            className="ArgumentsColumnLogo"
            webpImage={hautDeGammeLogoWebp}
            image={hautDeGammeLogo}
            alt="VTC-confort-senegal"
            width={'98'}
            height={'98'}
          />
          <div className="ArgumentsSubContainer">
            <h2 className="ArgumentsColumnTitle">{argumentsSection.title1}</h2>
            <p className="ParagraphCenter">{argumentsSection.arg11}</p>
            <p className="ParagraphCenter">{argumentsSection.arg12}</p>
            <p className="ParagraphCenter">{argumentsSection.arg13}</p>
          </div>
        </div>

        <div className="ArgumentsColumn ArgumentsColumn2">
          <ImageFormatter
            className="ArgumentsColumnLogo"
            webpImage={feuilleLogoWebp}
            image={feuilleLogo}
            alt="VTC-ecologiques-senegal"
            width={'98'}
            height={'98'}
          />
          <div className="ArgumentsSubContainer">
            <h2 className="ArgumentsColumnTitle">{argumentsSection.title2}</h2>
            <p className="ParagraphCenter">{argumentsSection.arg21}</p>
            <p className="ParagraphCenter">{argumentsSection.arg22}</p>
          </div>
        </div>

        <div className="ArgumentsColumn ArgumentsColumn3">
          <ImageFormatter
            className="ArgumentsColumnLogo"
            webpImage={connecteLogoWebp}
            image={connecteLogo}
            alt="reserver-VTC-senegal-transfert-aeroport-DSS"
            width={'98'}
            height={'98'}
          />
          <div className="ArgumentsSubContainer">
            <h2 className="ArgumentsColumnTitle">{argumentsSection.title3}</h2>
            <p className="ParagraphCenter">{argumentsSection.arg31}</p>
            <p className="ParagraphCenter">{argumentsSection.arg32}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Arguments;
