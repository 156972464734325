import { useCallback, createContext, useMemo, useState } from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import { langConfig } from '../../config/config';

export const LangContext = createContext();

export const LangContextProvider = ({ children }) => {
  const [lang, setLang] = useState(langConfig.DEFAULT_LANG);

  const handleLangChange = useCallback((selectedLang) => {
    // setLang(selectedLang);
    const langUrl = langConfig.LANGS_URLS[selectedLang];
    window.location = langUrl;
  }, []);

  const contextValue = useMemo(() => {
    return {
      lang,
      setLang,
      moment,
      handleLangChange,
    };
  }, [lang, handleLangChange]);

  return (
    <LangContext.Provider value={contextValue}>{children}</LangContext.Provider>
  );
};
