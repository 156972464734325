export const langConfig = {
  DEFAULT_LANG: 'fr',
  AVAILABLE_LANGS: ['fr', 'en', 'es', 'br'],
  LANG_SELECTOR_DISPLAY: ['Français', 'English', 'Español', 'Português'],
  LANGS_URLS: {
    fr: '/',
    en: '/en/',
    es: '/es/',
    br: '/br/',
  },
};

export const helmetPages = {
  HOME: 'home',
  AIRPORT: 'airport',
  CITY: 'city',
  LONG: 'long'
}

export const pagesConfig = {
  FR_HOME: '/',
  FR_SERVICE_AIRPORTS: '/transferts-aeroports',
  FR_SERVICE_CITY: '/transferts-urbains',
  FR_SERVICE_LONG: '/location-longue-duree',
  EN_HOME: '/en',
  EN_SERVICE_AIRPORTS: '/en/airports-transfers',
  EN_SERVICE_CITY: '/en/city-to-city-rides',
  EN_SERVICE_LONG: '/en/long-term-rental',
  ES_HOME: '/es/',
  ES_SERVICE_AIRPORTS: '/es/traslados-aeropuerto',
  ES_SERVICE_CITY: '/es/traslados-interurbanos',
  ES_SERVICE_LONG: '/es/alquiler-largo-plazo',
  BR_HOME: '/br/',
  BR_SERVICE_AIRPORTS: '/br/transfers-aeroporto',
  BR_SERVICE_CITY: '/br/viagens-interurbanos',
  BR_SERVICE_LONG: '/br/aluguel-longa-duracao',
};

export const emailConfig = {
  EMAIL_SERVICE_ID: 'netim',
  EMAIL_TEMPLATE_BOOKING_ID: 'yobale_reservation_admin',
  EMAIL_TEMPLATE_CONTACT_ID: 'yobale_formulaire',
  EMAIL_SERVICE_KEY: 'user_2AdGSeLLpGo6uxxkJoBB9',
};

export const whatsappConfig = {
  WHATSAPP_COMPANY_NUMBER: '221786330808',
  WHATSAPP_URL: 'https://api.whatsapp.com/send?phone=',
};

export const webConfig = {
  WEB_CONFIG_URL: 'https://raw.githubusercontent.com/raphaelpg/yobale-public/main/config/config_web.json'
}

export const googleConfig = {
  GOOGLE_PLACE_ID: 'ChIJk449GE31-kwRcJDM8GK48bg',
  GOOGLE_PLACES_API_KEY: 'AIzaSyB3aYKVSL9R3Bw3ojVzLqcClSxoc6ombE8',
  GOOGLE_WRITE_REVIEW_URL:
    'https://search.google.com/local/writereview?placeid=',
  GOOGLE_READ_REVIEWS_URL: 'https://search.google.com/local/reviews?placeid=',
  GOOGLE_RATING_DECIMALS: 1,
};

export const tripAdvisorConfig = {
  TRIP_ADVISOR_PRODUCT_URL:
    'https://www.tripadvisor.com/Attraction_Review-g811262-d25439304-Reviews-Yobale-Rufisque_Dakar_Region.html',
  TRIP_ADVISOR_READ_REVIEWS_FR_URL:
    'https://www.tripadvisor.fr/Attraction_Review-g811262-d25439304-Reviews-Yobale-Rufisque_Dakar_Region.html',
  TRIP_ADVISOR_READ_REVIEWS_EN_URL:
    'https://www.tripadvisor.com/Attraction_Review-g811262-d25439304-Reviews-Yobale-Rufisque_Dakar_Region.html',
  TRIP_ADVISOR_READ_REVIEWS_ES_URL:
    'https://www.tripadvisor.es/Attraction_Review-g811262-d25439304-Reviews-Yobale-Rufisque_Dakar_Region.html',
  TRIP_ADVISOR_GOOD_REVIEW_FR_URL:
    'https://www.tripadvisor.fr/ShowUserReviews-g811262-d25439304-r914114228-Yobale-Rufisque_Dakar_Region.html',
  TRIP_ADVISOR_GOOD_REVIEW_EN_URL:
    'https://www.tripadvisor.com/ShowUserReviews-g811262-d25439304-r914114228-Yobale-Rufisque_Dakar_Region.html',
  TRIP_ADVISOR_GOOD_REVIEW_ES_URL:
    'https://www.tripadvisor.es/ShowUserReviews-g811262-d25439304-r914114228-Yobale-Rufisque_Dakar_Region.html',
};

export const ratingsConfig = {
  PUBLIC_RATINGS_URL:
    'https://raw.githubusercontent.com/raphaelpg/yobale-public/main/reviews/reviews.json',
  DYNAMIC_RATINGS: false
};

export const reviewsConfig = {
  textLength: 180,
};

export const carouselConfig = {
  dots: false,
  arrows: false,
  infinite: true,
  pauseOnHover: false,
  // autoplay: true,
  autoplay: false,
  autoplaySpeed: 10000,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: '10%',
  touchThreshold: 100,
  swipeToSlide: true,
  accessibility: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        centerPadding: '0px',
      },
    },
  ],
};

export const delaysConfig = {
  BACK_TO_HOMEPAGE_BOOKING_FORM_DELAY: 500,
}

export const defaultWebConfig = {
    "reviews": {
    "google": 20,
    "ta": 25,
    "googleRating": 4.8,
    "taRating": 5
  },
  "destinationsList":
    [
      "Dakar",
      "Pikine",
      "Thiès",
      "Mbour",
      "Saly Portudal",
      "Somone",
      "Ngaparou",
      "Popenguine",
      "Nianing",
      "Joal-Fadiouth",
      "Kaolack",
      "Saint-Louis",
      "Touba",
      "Toubab Dialaw",
      "Rufisque",
      "Diamniadio",
      "Ziguinchor",
      "Kolda",
      "Cap Skirring",
      "Fatick",
      "Kédougou",
      "Tambacounda",
      "Matam",
      "Louga",
      "Kaffrine",
      "Bambey",
      "Diourbel",
      "Richard Toll",
      "Sokone",
      "Palmarin",
      "Toubakouta",
      "Karang"
    ],
  "hotelList": 
    [
      "Pullman Dakar Teranga (Dakar)",
      "Terrou-Bi Hotel (Dakar)",
      "Radisson Blu Hotel (Dakar)",
      "King Fahd Palace Hotel (Dakar)",
      "Faidherbe Hotel (Dakar)",
      "Novotel Dakar",
      "Azalaï Hôtel Dakar",
      "Hôtel Ibis Dakar",
      "Hotel Savana (Dakar)",
      "Yaas Hotel (Dakar)",
      "Onomo Hotel Dakar",
      "Casa Mara (Dakar)",
      "Hotel Le Djoloff (Dakar)",
      "Aquanique luxury apartments (Dakar)",
      "Hôtel Lagon 2 (Dakar)",
      "Hôtel Jardin Savana (Dakar)",
      "Hôtel Café de Rome (Dakar)",
      "Hôtel Le Ndiambour (Dakar)",
      "Hôtel Casino du Cap-Vert (Dakar)",
      "Onomo Hotel (Dakar)",
      "Le Grand Hôtel de Thiès",
      "Residence Blue (Thiès)",
      "Espace Hotel Sobobade (Toubab Dialaw)",
      "Iris Hotel (Toubab Dialaw)",
      "Hôtel La Pierre de Lisse (Ndayane)",
      "Hôtel Les Manguiers de Guéréo (Guéréo)",
      "Royal Horizon Baobab (Somone)",
      "AFRICA QUEEN (Somone)",
      "Movenpick Resort Lamantin (Saly)",
      "Rhino Resort Hotel & Spa (Saly)",
      "Hotel Club Royal Saly (Saly)",
      "Royam Hôtel (Saly)",
      "Hôtel Neptune (Saly)",
      "Hôtel Palm Beach Resort & Spa(Saly)",
      "Le Saly Hôtel & l'Hôtel Club Les Filaos (Saly)",
      "Hôtel Terra Lodge (Mbour)",
      "Tama Lodge (Mbour)",
      "Hotel Restaurant Casa Coco II (Nianing)",
      "Maison Couleur Passion (Nianing)",
      "Hotel RIU Baobab (Pointe Sarène)",
      "Souimanga Lodge (Fimela)",
      "Ecolodge de Simal (Simal)",
      "La Palmeraie de Ngor (Yayem)",
      "Boundao Lodge (Yayem)",
      "Yokan Lodge (Palmarin)",
      "Patrick's Lodge (Palmarin)",
      "Ecolodge De Palmarin",
      "Les Paletuviers Boutique Hotel (Toubakouta)",
      "Fathala Wildlife Reserve (Karang)",
      "Ecolodge de Lompoul (Lompoul)",
      "Horizons Lodges (Lompoul)",
      "Hotel Cap Saint Louis",
      "Hotel de la Poste (Saint-Louis)",
      "Hôtel La Résidence (Saint-Louis)",
      "Hôtel Dior (Saint-Louis)"
    ],
  "prices": [
    {
      "en": {
        "from": "Dakar Airport AIBD DSS",
        "to": "Dakar center"
      },
      "fr": {
        "from": "Aéroport Dakar AIBD DSS",
        "to": "Dakar centre"
      },
      "es": {
        "from": "Aeropuerto de Dakar AIBD DSS",
        "to": "Centro de Dakar"
      },
      "br": {
        "from": "Aeroporto de Dakar AIBD DSS",
        "to": "Centro de Dakar"
      },
      "priceFCFA": 35000,
      "priceEUR": 54,
      "priceUSD": 58
    },
    {
      "en": {
        "from": "Dakar Airport AIBD DSS",
        "to": "Saly Area*"
      },
      "fr": {
        "from": "Aéroport Dakar AIBD DSS",
        "to": "Zone Saly*"
      },
      "es": {
        "from": "Aeropuerto de Dakar AIBD DSS",
        "to": "Zona Saly*"
      },
      "br": {
        "from": "Aeroporto de Dakar AIBD DSS",
        "to": "Zona Saly*"
      },
      "priceFCFA": 30000,
      "priceEUR": 46,
      "priceUSD": 50
    },
    {
      "en": {
        "from": "Dakar Airport AIBD DSS",
        "to": "Hotel Riu Baobab"
      },
      "fr": {
        "from": "Aéroport Dakar AIBD DSS",
        "to": "Hotel Riu Baobab"
      },
      "es": {
        "from": "Aeropuerto de Dakar AIBD DSS",
        "to": "Hotel Riu Baobab"
      },
      "br": {
        "from": "Aeroporto de Dakar AIBD DSS",
        "to": "Hotel Riu Baobab"
      },
      "priceFCFA": 35000,
      "priceEUR": 54,
      "priceUSD": 58
    },
    {
      "en": {
        "from": "Dakar Airport AIBD DSS",
        "to": "Palmarin"
      },
      "fr": {
        "from": "Aéroport Dakar AIBD DSS",
        "to": "Palmarin"
      },
      "es": {
        "from": "Aeropuerto de Dakar AIBD DSS",
        "to": "Palmarin"
      },
      "br": {
        "from": "Aeroporto de Dakar AIBD DSS",
        "to": "Palmarin"
      },
      "priceFCFA": 65000,
      "priceEUR": 99,
      "priceUSD": 107
    },
    {
      "en": {
        "from": "Dakar Airport AIBD DSS",
        "to": "Sine Saloum Area**"
      },
      "fr": {
        "from": "Aéroport Dakar AIBD DSS",
        "to": "Zone Sine Saloum**"
      },
      "es": {
        "from": "Aeropuerto de Dakar AIBD DSS",
        "to": "Zona Sine Saloum**"
      },
      "br": {
        "from": "Aeroporto de Dakar AIBD DSS",
        "to": "Zona Sine Saloum**"
      },
      "priceFCFA": 40000,
      "priceEUR": 61,
      "priceUSD": 66
    },
    {
      "en": {
        "from": "Saly Area*",
        "to": "Sine Saloum Area**"
      },
      "fr": {
        "from": "Zone Saly*",
        "to": "Zone Sine Saloum**"
      },
      "es": {
        "from": "Zona Saly*",
        "to": "Zona Sine Saloum**"
      },
      "br": {
        "from": "Zona Saly*",
        "to": "Zona Sine Saloum**"
      },
      "priceFCFA": 17000,
      "priceEUR": 26,
      "priceUSD": 32
    },
    {
      "en": {
        "from": "Saly Area*",
        "to": "Dakar center"
      },
      "fr": {
        "from": "Zone Saly*",
        "to": "Dakar centre"
      },
      "es": {
        "from": "Zona Saly*",
        "to": "Centro de Dakar"
      },
      "br": {
        "from": "Zona Saly*",
        "to": "Centro de Dakar"
      },
      "priceFCFA": 55000,
      "priceEUR": 84,
      "priceUSD": 91
    },
    {
      "en": {
        "from": "Dakar center",
        "to": "Hotel Riu Baobab"
      },
      "fr": {
        "from": "Dakar centre",
        "to": "Hotel Riu Baobab"
      },
      "es": {
        "from": "Centro de Dakar",
        "to": "Hotel Riu Baobab"
      },
      "br": {
        "from": "Centro de Dakar",
        "to": "Hotel Riu Baobab"
      },
      "priceFCFA": 65000,
      "priceEUR": 99,
      "priceUSD": 107
    }
  ]
}