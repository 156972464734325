const nfSenegal = require('../images/externals/nf.png')
const nfSenegalWebp = require('../images/externals/nf.webp')
const itourismtv = require('../images/externals/itourismtv.png')
const itourismtvWebp = require('../images/externals/itourismtv.webp')
const travelBaobab = require('../images/externals/travel-baobab.png')
const travelBaobabWebp = require('../images/externals/travel-baobab.webp')
const viator = require('../images/externals/viator.png')
const viatorWebp = require('../images/externals/viator.webp')

export const partnersLinks = [
  {
    url: "https://nfsenegal.com/index.php",
    name: "Nouvelles Frontières Sénégal",
    alt:"nouveles-frontieres-senegal",
    image: nfSenegal,
    webp: nfSenegalWebp
  },
  {
    url: "https://www.itourismetv.com/",
    name: "I Tourisme TV",
    alt:"itourisme-tv",
    image: itourismtv,
    webp: itourismtvWebp
  },
  {
    url: "https://travelbaobab.com/",
    name: "Travel Baobab",
    alt:"travel-baobab",
    image: travelBaobab,
    webp: travelBaobabWebp
  },
  {
    url: "https://www.viator.com/",
    name: "Viator",
    alt:"viator",
    image: viator,
    webp: viatorWebp
  }
]